import React from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Button, Form } from '../../components';

import css from './StripeOnboardingForm.css';

const StripeOnboardingFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{
      ...arrayMutators,
    }}
    render={fieldRenderProps => {
      const {
        className,
        disabled,
        handleSubmit,
        ready,
        submitButtonText,
      } = fieldRenderProps;

      const classes = classNames(css.root, className, {
        [css.disabled]: disabled,
      });

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={false}
            disabled={false}
            ready={ready}
          >
            {submitButtonText}
          </Button>
        </Form>
      );
    }}
  />
);

StripeOnboardingFormComponent.defaultProps = {
  className: null,
  createStripeAccountError: null,
  disabled: false,
  inProgress: false,
  ready: false,
  submitButtonText: null,
  currentUserId: null,
  fieldRenderProps: null,
};

StripeOnboardingFormComponent.propTypes = {
  className: string,
  createStripeAccountError: object,
  disabled: bool,
  inProgress: bool,
  ready: bool,
  submitButtonText: string,
  currentUserId: propTypes.uuid,
  fieldRenderProps: shape({
    handleSubmit: func,
    invalid: bool,
    pristine: bool,
    values: object,
  }),

  // from injectIntl
  intl: intlShape.isRequired,
};

const StripeOnboardingForm = compose(injectIntl)(StripeOnboardingFormComponent);

export default StripeOnboardingForm;
