import React from 'react';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ExternalLink,
} from '../../components';

import css from './HelpPage.css';
import { StaticPage, TopbarContainer } from '../../containers';

const HelpPage = () => {
  return (
    <StaticPage
      title="Help | Jelly Places"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'HelpPage',
        description: 'Get Help with Jelly Places',
        name: 'Help',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h2 className={css.pageTitle}>Hi, how may we help you today?</h2>

          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <p>Jelly Places, the #getjelly team is here to help. We believe no Jelly should be left behind. :)</p>
              <p>Most importantly, we will do everything we can to help you with your related
                Jelly Places needs. Please feel free to contact us at &nbsp;
                <ExternalLink href='mailto:help@jellyplaces.com'>help@jellyplaces.com</ExternalLink>
                &nbsp;.</p>
              <p>If there is something we do not know because we are just getting started here,
                we’ll do our best to find the answer and/or point you in the right direction.</p>
              <p>If our response time is not as swift as you’d like, we apologize in advance.</p>
              <p>As we continue to grow and develop a long-term relationship with you,
                we will be sure to respond with minimal hesitation.</p>
              <p>Thank you for your thoughtful understanding.</p>
              <p><br/><b>All the best,</b><br/></p>
              <p><b>The Jelly Places team</b></p>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default HelpPage;
