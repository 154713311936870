import React from 'react';
import config from '../../config';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ExternalLink,
} from '../../components';
import css from './ContactUsPage.css';
import PropTypes from 'prop-types';
import 'firebase/database';

import { ToastContainer } from 'react-toastify';

const ContactUsPage = props => {
  return (
    <StaticPage
      title="Contact Us | Jelly Places"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'ContactUsPage',
        description: 'Contact Jelly Places',
        name: 'Contact us page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
          <ToastContainer delay={1000} autoClose={6000} position={'top-center'} />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h1 className={css.pageTitle}>Contact Jelly Places</h1>
          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <p>Jelly Places, #getjelly contact alert.</p>
              <p>We love hearing from the Jelly Places community.</p>
              <p>Please feel free to contact us at
                &nbsp;<ExternalLink href='mailto:help@jellyplaces.com'>help@jellyplaces.com</ExternalLink>&nbsp;
                .</p>
              <p><br/><b>All the best,</b><br/></p>
              <p><br/><b>Jelly Places team</b><br/></p>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};
ContactUsPage.defaultProps = {
  showMessageSent: null,
};
const { bool, func } = PropTypes;
ContactUsPage.propTypes = {};
export default ContactUsPage;
