import React from 'react';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';

import css from './FeeSchedulePage.css';
import { StaticPage, TopbarContainer } from '../../containers';

const FeeSchedulePage = () => {
  return (
    <StaticPage
      title="Help | Jelly Places"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'HelpPage',
        description: 'Get Help with Jelly Places',
        name: 'Help',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h2 className={css.pageTitle}>Membership Rates</h2>

          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <p className={css.columnTitle}><b>Host</b></p>
              <p className={css.columnTitle}><b>Guest</b></p>
              <p className={css.columnTitle}><b>Type</b></p>

              <p className={css.columnCell}>$5.00</p>
              <p className={css.columnCell}>3rd party electronic processing rates</p>
              <p className={css.columnCell}>Standard Membership</p>

              <p className={css.columnCellEven}>$20.00</p>
              <p className={css.columnCellEven}>3rd party electronic processing rates</p>
              <p className={css.columnCellEven}>Corporate Membership</p>

              <p className={css.columnCell}>$45.00</p>
              <p className={css.columnCell}>3rd party electronic processing rates</p>
              <p className={css.columnCell}>Enterprise Membership</p>

              <p className={css.columnCellEven}>Custom</p>
              <p className={css.columnCellEven}>3rd party electronic processing rates</p>
              <p className={css.columnCellEven}>Enterprise Plus Membership</p>
            </div>
          </div>

          <h2 className={css.pageTitle}>Transaction Rates</h2>

          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <p className={css.columnTitle}><b>Host</b></p>
              <p className={css.columnTitle}><b>Guest</b></p>
              <p className={css.columnTitle}><b>Type</b></p>

              <p className={css.columnCell}>20%</p>
              <p className={css.columnCell}>3rd party electronic processing rates</p>
              <p className={css.columnCell}>Standard Membership</p>

              <p className={css.columnCellEven}>20%</p>
              <p className={css.columnCellEven}>3rd party electronic processing rates</p>
              <p className={css.columnCellEven}>Corporate Membership</p>

              <p className={css.columnCell}>20%</p>
              <p className={css.columnCell}>3rd party electronic processing rates</p>
              <p className={css.columnCell}>Enterprise Membership</p>

              <p className={css.columnCellEven}>20%</p>
              <p className={css.columnCellEven}>3rd party electronic processing rates</p>
              <p className={css.columnCellEven}>Enterprise Plus Membership</p>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default FeeSchedulePage;
