import firebase from 'firebase/app';
import 'firebase/analytics';

export class LoggingAnalyticsHandler {
  trackPageView(url) {
    console.log('DEVELOPMENT: Analytics page view:', url);
  }
}

export class GoogleAnalyticsHandler {
  trackPageView(url) {
    firebase.analytics().logEvent(url);
    // console.log('PRODUCTION: Analytics Page View:', url);
  }
}
