import React from 'react';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';

import css from './CancellationAndRefundPolicyPage.css';
import { StaticPage, TopbarContainer } from '../../containers';

const CancellationAndRefundPolicyPage = () => {
  return (
    <StaticPage
      title="Help | Jelly Places"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'HelpPage',
        description: 'Get Help with Jelly Places',
        name: 'Help',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h2 className={css.pageTitle}>Cancellation & Refund Policy</h2>

          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <p className={css.columnTitle}><b>Cancellation Requirements</b></p>
              <p className={css.columnTitle}><b>Booking Type</b></p>
              <p className={css.columnTitle}><b>Guest Refund</b></p>
              <p className={css.columnTitle}><b>Host</b></p>

              <p className={css.columnCell}>1 Hour prior to booking time</p>
              <p className={css.columnCell}>Hourly</p>
              <p className={css.columnCell}>100%, less Stripe processing fee</p>
              <p className={css.columnCell}>0%</p>

              <p className={css.columnCellEven}>Less than 1 Hour prior to booking time</p>
              <p className={css.columnCellEven}>Hourly</p>
              <p className={css.columnCellEven}>Non-refundable</p>
              <p className={css.columnCellEven}>100%, less Jelly Places service fee</p>

              <p className={css.columnCell}>24 hours prior to booking time</p>
              <p className={css.columnCell}>Daily</p>
              <p className={css.columnCell}>100%, less Stripe processing fee</p>
              <p className={css.columnCell}>0%</p>

              <p className={css.columnCellEven}>Less than 24 hours prior to booking time</p>
              <p className={css.columnCellEven}>Daily</p>
              <p className={css.columnCellEven}>Non-refundable</p>
              <p className={css.columnCellEven}>100%, less Jelly Places service fee</p>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default CancellationAndRefundPolicyPage;
