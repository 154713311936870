import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from '../../util/reactIntl';
import { ExternalLink, NamedLink } from '../../components';
import classNames from 'classnames';
import {
  ensureUser,
  ensureCurrentUser,
  userDisplayNameAsString,
  userAbbreviatedName,
} from '../../util/data';

import css from './InfoBanner.css';

class InfoBanner extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
    this.dismissBanner = this.dismissBanner.bind(this);
  }

  componentDidMount() {
    const cookies = document.cookie.split('; ').reduce((acc, c) => {
      const [name, value] = c.split('=');
      return { ...acc, [name]: decodeURIComponent(value) };
    }, {});

    if (cookies.euCookiesAccepted !== '1') {
      this.setState({ show: true });
    }
  }

  dismissBanner() {
    this.setState({ show: false });
  }

  render() {
    const { className, rootClassName } = this.props;
    const isServer = typeof window === 'undefined';
    // Server side doesn't know about cookie consent
    if (isServer || !this.state.show) {
      return null;
    } else {
      const classes = classNames(rootClassName || css.root, className);
      const bannerLinkExternal = (
        <ExternalLink href="https://yourlinkgoeshere" className={css.linkExternal}>
          <FormattedMessage id="InfoBanner.linkExternal" />
        </ExternalLink>
      );
      const bannerLinkInternal = (
        <NamedLink name="LandingPage" className={css.linkInternal}>
          <FormattedMessage id="InfoBanner.linkInternal" />
        </NamedLink>
      );

      // Great examples found here:
      // https://stackoverflow.com/questions/31079081/programmatically-navigate-using-react-router
      const SignupButton = withRouter(({ history }) => (
        <button
          className={css.continueBtn}
          type={'button'}
          onClick={() => {
            history.push('/signup');
          }}
        >
          <FormattedMessage id="InfoBanner.buttonText" />
        </button>
      ));

      const DismissButton = (
        <button className={css.closeBtn} onClick={this.dismissBanner}>
          &#120;
        </button>
      );

      return (
        <div className={classes}>
          <div className={css.message}>
            <FormattedMessage id="InfoBanner.message" values={{ bannerLinkInternal }} />
          </div>
          <SignupButton />
          {DismissButton}
        </div>
      );
    }
  }
}

const { string } = PropTypes;
InfoBanner.defaultProps = {
  className: null,
  rootClassName: null,
};
InfoBanner.propTypes = {
  classNames: string,
  rootClassName: string,
};
export default InfoBanner;
