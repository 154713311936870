import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ExternalLink } from '../../components';
import css from './TermsOfService.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last Updated: December 29, 2020</p>

      <p>
        Welcome to JELLY PLACES! JELLY PLACES is an online and mobile platform that helps you
        find inspirational spaces to book and host on-demand. This Terms of Use, together with the
        documents referenced herein (collectively, the “Terms”), to JELLY PLACES’ websites,
        progressive web apps, web apps, native apps, mobile apps, desktop applications, software
        platforms, or online offerings (collectively, the “Platform”) will automatically apply to you. JELLY
        PLACES (“we,” “us,” or “our”) permits you to use the Platform subject to these Terms and any
        applicable laws. You may thus only utilize the Platform in accordance with these Terms.
        PLEASE READ THESE TERMS CAREFULLY BEFORE ACCESSING OR USING THE
        PLATFORM.
      </p>

      <p>
        BY CLICKING TO ACCEPT OR AGREE TO THE TERMS WHEN THIS OPTION IS MADE
        AVAILABLE TO YOU, YOU AGREE TO BE BOUND BY THESE TERMS, WHICH INCLUDE
        THE JELLY PLACES PRIVACY POLICY (located at https://jellyplaces.com/privacy-policy) AND
        ALL OTHER POLICIES, RULES, GUIDELINES, TERMS AND CONDITIONS ESTABLISHED
        FOR THE PLATFORM AS SET FORTH THEREIN, WHICH ARE HEREBY INCORPORATED
        BY REFERENCE. THESE TERMS ESTABLISH A CONTRACTUAL RELATIONSHIP
        BETWEEN YOU AND JELLY PLACES. IF YOU DO NOT AGREE TO THESE TERMS, YOU
        MAY NOT ACCESS OR USE THE PLATFORM. THESE TERMS EXPRESSLY SUPERSEDE
        PRIOR AGREEMENTS OR ARRANGEMENTS WITH YOU. JELLY PLACES MAY
        IMMEDIATELY TERMINATE THESE TERMS OR ANY SERVICES WITH RESPECT TO YOU,
        OR GENERALLY CEASE OFFERING OR DENY ACCESS TO THE PLATFORM OR ANY
        PORTION THEREOF, AT ANY TIME FOR ANY REASON.
      </p>

      <p>
        PLEASE NOTE: SECTION 11 OF THESE TERMS AFFECTS HOW DISPUTES BETWEEN
        YOU AND JELLY PLACES ARE RESOLVED. IT CONTAINS AN ARBITRATION AGREEMENT
        THAT REQUIRES DISPUTES TO BE ARBITRATED ON AN INDIVIDUAL BASIS AND
        PROHIBITS CLASS ACTION CLAIMS. BY ACCEPTING THESE TERMS OF USE, YOU
        AGREE TO BE BOUND BY THAT ARBITRATION AGREEMENT. PLEASE READ IT
        CAREFULLY.
      </p>

      <h2 className={css.underlinedSubtitle}>IMPORTANT NOTICES</h2>

      <p>
        PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE DOWNLOADING,
        INSTALLING, OR USING THE PLATFORM. <b>IF YOU DO NOT AGREE TO THESE TERMS OF
        USE, YOU ARE NOT PERMITTED TO USE THE PLATFORM AND YOU MUST
        IMMEDIATELY CEASE DOWNLOADING, INSTALLING, OR USING THE PLATFORM.</b>
      </p>

      <p>
        BY DOWNLOADING, INSTALLING, ACCESSING, BROWSING, SIGNING UP, OR USING
        THIS PLATFORM, YOU ACCEPT AND AGREE TO THESE TERMS OF USE WHICH BIND
        YOU LEGALLY, AND YOU FURTHER:
      </p>

      <p>
        <ol className={css.letterList}>
          <li>
            ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTOOD THESE TERMS OF USE;
          </li>
          <li>
            ACKNOWLEDGE THAT YOU ARE 18 YEARS OF AGE OR OLDER;
          </li>
          <li>
            ACKNOWLEDGE THAT WE WILL COLLECT AND USE YOUR INFORMATION,
            INTERNALLY OR VIA THIRD PARTY SERVICE PROVIDERS, TO PROVIDE THE
            PRODUCTS AND SERVICES YOU REQUEST THROUGH THE PLATFORM;
          </li>
          <li>
            WARRANT THAT ALL INFORMATION PROVIDED UPON REGISTRATION AS PART OF
            YOUR ACCOUNT IS TRUE, COMPLETE, AND ACCURATE, AND THAT YOU WILL
            PROMPTLY INFORM US OF ANY CHANGES TO SUCH INFORMATION BY UPDATING
            THE INFORMATION ON YOUR ACCOUNT;
          </li>
          <li>
            AGREE TO TERMS OF THE JELLY PLACES’ PRIVACY POLICY (“PRIVACY POLICY”),
            AVAILABLE AT&nbsp;
            <ExternalLink href={'https://jellyplaces.com/privacy-policy'} >
              https://jellyplaces.com/privacy-policy
            </ExternalLink>;
            and
          </li>
          <li>
            ACKNOWLEDGE AND AGREE THAT THE PLATFORM ACCESSES AND USES YOUR
            LOCATION INFORMATION BASED ON THE LOCATION OF YOUR DEVICE AT THE
            TIME OF CONNECTING TO THE PLATFORM.
          </li>
        </ol>
        If at any time after reviewing or using the Platform you wish to terminate such use or these
        Terms of Use, you must uninstall and remove the Platform from your Device completely and
        delete any copies thereof in your possession.
      </p>

      <h2 className={css.underlinedSubtitle}>USER SERVICES AGREEMENT</h2>

      <p>
        JELLY PLACES is an online platform through which Hosts (defined below) may create listings
        for Spaces (defined below), and Guests (defined below) may learn about and book Places and
        Spaces directly with the Hosts (the “Service”). You understand and agree that JELLY PLACES
        is not a party to any agreements entered into between Hosts and Guests, nor is JELLY
        PLACES a real estate broker, agent or insurer. JELLY PLACES has no control over the conduct
        of Hosts, Guests and other users of the service or any Spaces, and disclaims all liability in this
        regard to the maximum extent permitted by law. Notwithstanding anything to the contrary in
        these Terms of Use, no portion of the fees will deemed to be compensation for anything other
        than the provision of the Service.
      </p>

      <p>
        These Terms of Use include a release by you of all claims for damages against us that may
        arise out of your use of the Platform. By accessing or using the Platform, you are agreeing to
        this release.
      </p>

      <h2>SECTION 1 – DEFINITIONS</h2>

      <p>
        Device - Any Android or iOS compatible basic phone, tablet, or smartphone, smart watch, smart
        home, smart vehicle, including accessories and ancillary related devices.
      </p>

      <p>
        Guests, also known as Explorers – JELLY PLACES’ users who use Host
      </p>

      <p>
        Services. Hosts - JELLY PLACES’ users who offer Host Services to Guests.
      </p>

      <p>
        Host Services – Hosts may use our Platform to offer a Space and related services to a Guest to
        use for a specific time and purposes (an “Event”) using our Platform. The details of each Space,
        including its availability, booking price, additional fees, rules, and other terms are provided in a
        “Listing.” In addition, Hosts may provide goods or services for an Event at an additional cost
        (“Host Add-Ons”). Hosts are solely responsible for their Spaces, Listings, and Host Services.
        Space – A specific location, item, room, site, or other area, privately owned or privately licensed
        property. Please note, JELLY PLACES does not own, lease, or control the Spaces listed, and
        does not make decisions surrounding whether to accept a Booking.
      </p>

      <h2>SECTION 2 – GENERAL TERMS</h2>
      <h2>2.1 Use of JELLY PLACES’ Services</h2>
      <p>
        <b>(a) Third Party Services and Content.</b> Our Platform is made available to you in connection
        with the offering of third party services that JELLY PLACES does not control. JELLY PLACES
        does not endorse any such third party services and in no event shall JELLY PLACES be liable
        or responsible for any third party services or third party services providers (like Hosts).
      </p>

      <p>
        <b>(b) Planning an Event.</b> Our Platform allows users to connect to third-parties to plan Events.
        Users control the availability, quality, and terms of their Listings and Host Services. It is your
        responsibility to evaluate each Guest, and Host with whom you choose to contract,
        communicate, or otherwise interact. JELLY PLACES is not responsible for the content posted
        by our users, nor are we responsible for the quality of Spaces or Host Services offered. We do
        not assume any responsibility for the confirmation of any user’s identity, however,
        notwithstanding the foregoing, and as permitted by applicable laws, we may, but have no
        obligation to take efforts to verify the identity of users and/or screen them against public and
        private databases for the purposes of preventing fraudulent activity and providing a safe
        transacting community.
      </p>

      <p>
        <b>(c) Bookings.</b> When users enter into Bookings with one another (i.e when a Host confirms a
        Booking request from a Guest), they enter into a contract directly with one another. <b>JELLY
        PLACES does not become a party to the contract between users.</b> JELLY PLACES is not
        acting as an agent for any user, except for as specified in Section 2.4(g) <b>(Appointment of
        JELLY PLACES as a Limited Collection Agent).</b> Users are prohibited from using the JELLY
        PLACES Platform to facilitate contracts outside of the Platform.
      </p>

      <p>
        <b>(d) Communication with Jelly Places.</b> You agree that JELLY PLACES may contact you by
        telephone, text messages or SMS (including by an automatic telephone dialing system), through
        electronic messaging on the Platform, or via email at any of the communication methods
        provided by you or on your behalf in connection with your Account, including for marketing
        purposes. You
        understand that you are not required to provide consent to receive marketing communications
        as a condition of purchasing any goods or services, and you may opt out of those marketing
        communications at any time. You specifically consent to receive communications related to your
        Account through text messages, SMS, or other communications sent to your mobile device
        (“Mobile Notifications”). Standard mobile rates will apply for any Mobile Notifications you receive.
        You may stop receiving these Mobile Notifications at any point by responding to such message
        with “STOP” or following the instructions provided in our help center located at
        &nbsp;
        <ExternalLink href={'https://jellyplaces.com/help'} >
          https://jellyplaces.com/help
        </ExternalLink>
        &nbsp;
        here. However, if you do so, you may miss critical messages related
        to your Account. We are not responsible for and disclaim all liability for all harm or losses
        caused by disabling Mobile Notifications. Please contact us via email at
        &nbsp;
        <ExternalLink href={'mailto:help@jellyplaces.com'} >
          help@jellyplaces.com
        </ExternalLink>
        &nbsp;
        or open a support ticket through our Help Center at https://JellyPlaces.com/help if you
        experience issues when attempting to stop Mobile or other Notifications.
      </p>

      <p>
        <b>(e) Communications with Other Users.</b> The Platform allows you to communicate with other
        users without disclosing sensitive personal contact information. You may use the Platform only
        as permitted in this Services Agreement. You may not use the Platform to send messages that
        are unwanted or unrelated to Booking a Listing through the Platform, harass or attempt to
        market other services to users, or to solicit and/or send spam. We are not responsible for, and
        disclaim
        all liability resulting from, any losses or harm to you resulting from sharing personal or sensitive
        information with other users or communicating or engaging with users outside of the Platform.
        JELLY PLACES, at its sole option and without notice or any obligation to do so, may from time
        to time (i) remove communications among users which contain or share personal contact
        information, or (ii) suspend or terminate the accounts of users that share personal contact
        information.
      </p>

      <p>
        <b>(g) Contractual Disputes Between Users.</b> JELLY PLACES may facilitate the resolution of
        disputes between users but does not become a party to any contract by doing so. We are not
        responsible to you or any third party for providing, or for the condition or nature of, any Space,
        or Host Services. Hosts, and Guests are independent third-parties and are not affiliated,
        controlled by, or employed by JELLY PLACES. Hosts set their own prices, use their own
        facilities and resources, and may condition use of Spaces as they feel is appropriate for their
        business.
      </p>

      <h2>2.2 Your JELLY PLACES Account</h2>
      <p>
        <b>(a) Registration.</b> Before using the Platform or Services, you must create an account with JELLY
        PLACES (<b>“Account”</b>) and provide us with information about yourself or your company. If you
        are registering on behalf of a legal entity, you represent and warrant that you have the authority
        to legally bind that entity and grant all rights, permissions, and licenses contained in this
        Services Agreement or any other applicable terms. Misrepresenting your affiliation or
        association with an entity may result in immediate termination of your Account, ineligibility to
        participate in any of our Services, monetary damages, and reporting to the appropriate legal
        authority.
      </p>

      <p>
        <b>(b) Account Security.</b> You will be required to provide an email address and password to keep
        your Account secure (<b>“Credentials”</b>) and agree to keep your Credentials private and secure.
        You are responsible for any actions associated with your Account whether or not authorized.
        You are solely responsible for the actions or communications of your employees,
        representatives, hires, or agents (<b>“Agents”</b>). We are not responsible for and disclaim all liability
        for use of your Account by your Agents. You may not assign or transfer your Account to another
        party without our prior written consent.
      </p>

      <p>
        <b>(c) Account Updates.</b> We may ask that you update your Account or provide additional or
        different information at any time. This includes requiring additional details about Spaces or
        payment information. You are required to update all Account information necessary to maintain
        compliance with this Services Agreement, including but not limited to maintaining proper
        contact information and payment information.
      </p>

      <p>
        <b>(d) Account Termination by You.</b> You may terminate your Account at any time by contacting
        us at
        &nbsp;
        <ExternalLink href={'mailto:help@jellyplaces.com'} >
          help@jellyplaces.com
        </ExternalLink>.
        You are responsible for all activity associated with your Account
        made before it is closed including: (i) payment of Fees, Taxes, Fines or other charges; (ii)
        providing use of Spaces to Guests as previously Booked; or (iii) other liabilities caused by or
        resulting from use of the Platform or Service. We may retain content and continue to display
        and use any public content (including comments or reviews of Spaces) after your Account is
        terminated. Termination
        of your Account does not terminate any contractual arrangements you have with third parties,
        including any obligations to government entities for tax purposes.
      </p>

      <p>
        <b>(e) Account Termination by Us.</b> We may suspend your Account, including your ability to
        communicate with other users, receive payments, or complete Bookings, or terminate this
        Services Agreement and your Account at any time and based on our sole discretion without
        limitation. We are likely to terminate your account if (i) we believe that use of your Account
        poses a risk to JELLY PLACES, you, other users, or third parties; (ii) there is evidence of actual
        or potential fraud by you or on your behalf; (iii) you fail to respond to communications from us or
        other users; or (iv) you fail to comply with this Services Agreement and associated policies
        including the Cancellation and Refund Policy and other applicable law.
      </p>

      <p>
        <b>(f) Survival.</b> Upon termination of this Services Agreement or any agreement between you and
        JELLY PLACES, the clauses of this Services Agreement that reasonably should survive
        termination will remain in effect.
      </p>

      <h2>2.3 Fees and Taxes</h2>
      <p>
        <b>(a) Fees and Payments.</b> You understand that JELLY PLACES may initiate charges to you for
        the goods or services you receive. By using our Services, you authorize JELLY PLACES to
        receive and/or enable your payment of applicable charges for the goods or services (including
        third party services like Host Services) obtained through your use of the Platform and Platform
        Services. You acknowledge and accept that, as between you and JELLY PLACES, JELLY
        PLACES may revise charges for any services obtained through use of the Platform at any time
        in JELLY PLACES’ sole discretion. JELLY PLACES will make reasonable efforts to inform you
        of the charges incurred through your use of the Platform Services, but you shall be responsible
        for all charges incurred under your Account regardless of your awareness of such charges. The
        charges you incur may be owed directly to third party service providers (like Hosts), and JELLY
        PLACES will collect those charges from you, on the third party’s behalf as their limited collection
        agent, and payment of such charges by you will be considered the same as if made directly by
        you to the third party service provider. JELLY PLACES reserves the right to otherwise collect
        payment from you and pursue any remedies available to JELLY PLACES in circumstances in
        which you are deemed liable or owing for any unpaid fees, damages, fines or other sums for
        which you are responsible, as decided by JELLY PLACES in its sole discretion and in
        accordance with this Services Agreement, the applicable policy set by JELLY PLACES, and/or
        the terms of the Booking Agreement mentioned in the Listing.
      </p>

      <p>
        <b>(b) Fees Charged by JELLY PLACES.</b> JELLY PLACES charges services fees to its users in
        exchange for use of the Platform Services. Hosts are charged memberships and Host Fees;
        Guests are charged Guest Fees (collectively “Service Fees”). Service Fees are described in
        more detail on our Fees Schedule (located at _). All applicable Service Fees and Taxes will be
        displayed to the applicable user prior to completion of a Booking. JELLY PLACES reserves the
        right to alter or amend its fees at any time and such changes will become effective after
        reasonable notice has been given.
      </p>

      <p>
        <b>(c) Host Charges and Additional Fees.</b> A Booking provides a limited use of a Space as
        described in the Listing, if applicable, for which the Host charges a fee (the “Booking Price”). A
        Host may sometimes also charge a Cleaning Fee in association with the use of a Space. If the
        Host offers Host Add-Ons in a Booking, the Host may also charge a Host Add-On Fee. If you
        use a Space beyond that specified in your agreement with another user you will be responsible
        for payment of additional Fees (“Additional Fees”) as described on the Fees Schedule.
        Additional Fees are determined based on the factors described in the Listing. As a Guest, you
        are solely
        responsible for any damage done to the Space or the Amenities by anyone attending or working
        at your Event.
      </p>

      <p>
        <b>(d) Prepaid Fees.</b> Certain Bookings may require you to provide a portion of prepaid Fees or a
        refundable amount (“Deposit”) in advance. All Fees and Deposits, as applicable, will be
        identified prior to completing your Booking. If held by JELLY PLACES, at our option, Deposits
        may be credited to your Booking or returned when the Space is returned in acceptable
        condition and free of any material damage. While we may facilitate the collection of Fees and
        Deposits for Hosts, once remitted to Hosts, we are not responsible for the return of Fees or
        Deposits to Guests, where applicable.
      </p>

      <p>
        <b>(e) Taxes and Fines.</b> You are solely responsible for payment of all taxes, levies, penalties, and
        other costs imposed by any taxing authority or government agency related to Listing or Booking
        Spaces including any sales or occupancy tax, usage or permitting fees, duties, and other taxes
        imposed by municipalities, states, or governments through regulation, ordinance, law, or judicial
        or regulatory interpretation (collectively “Taxes”). Except as required by Law, JELLY PLACES
        will not calculate, track or pay Taxes or submit Tax reporting on your behalf. You are
        responsible for all Taxes owed for Booking or Listing a Space, without limitation, accurate
        calculation of Taxes due, timely remittance of Taxes to the appropriate taxing authority and
        maintenance of any required records and accounts. If any taxing authority demands that we pay
        such Taxes on your behalf, you are immediately liable to us for such Taxes and will reimburse
        or pay JELLY PLACES for such Taxes upon demand. You are also responsible for any
        penalties arising from your failing to comply with this Services Agreement including those
        issued by regulatory or taxing authorities, law enforcement, fire code or safety agencies, or
        other third parties; or that may be issued by us for losses we or users incur that are based on
        your failing to comply with this Services Agreement or misuse of the Platform, Services, or
        Space (collectively, “Fines”). You understand and agree that JELLY PLACES does not provide
        you with any advice or guidance of any kind or nature regarding Taxes and that you have been
        advised to consult with your tax advisor for any required advice or guidance regarding Taxes.
      </p>

      <p>
        <b>(f) Payment.</b> You will timely and fully pay any Fees, Deposits, Taxes, Fines, or other amounts
        you owe under this Services Agreement. If you owe amounts and we are unable to receive
        payment through the Platform for any reason, then we may require that you pay through other
        means (such as direct debit wire transfer). We may set-off any amounts owed to us through
        collection of funds that would otherwise be payable to you through the Platform. You are
        responsible for any costs or expenses associated with our recovering Fees, Taxes, or Fines
        owed, including our attorneys’ fees or expenses. In our discretion, any late payments of more
        than 5 days may incur a late charge of up to ten percent (10%) and accumulate interest of ten
        percent (10%) per month until the delinquency is resolved. We may use any legal means
        available to us to collect delinquent payments, including collection agencies or court filings.
      </p>

      <p>
        <b>(g) Payment Processing.</b> Acceptance and payment of funds between users or JELLY PLACES
        on the Platform (“Payment Processing”) is provided by Stripe. Your use of Payment Processing
        is subject to the Stripe Connected Account Agreement that includes the Stripe Services
        Agreement as may be modified by Stripe from time to time (collectively, the “Stripe Agreement”).
        As a condition using Payment Processing, you must provide accurate and complete information
        about you and your business and you authorize us to share this information to Stripe. All bank
        and credit card information is sent directly to and stored with Stripe using their security
        protocols. JELLY PLACES does not store your payment information on its systems and shall
        not have any responsibility for the safety or security of that information. Your use of Payment
        Processing is conditioned upon your compliance with the Stripe Agreement, and if the Stripe
        Agreement is terminated by Stripe you may not be able to use the Platform, or have your
        Account suspended
        or terminated. We may change or add other payment processing services at any time upon
        notice to you, which may be subject to additional terms or conditions.
      </p>

      <p>
        <b>Appointment of JELLY PLACES as a Limited Collection Agent.</b> Each user (such as Hosts,
        or Guests who facilitate certain transactions through our Platform) who collects payment for
        services provided via the Platform (“Collecting User”) hereby appoints JELLY PLACES as the
        Collecting User’s payment collection agent solely for the limited purpose of accepting funds
        from other users purchasing such services (“Paying User”).
        <br/><br/>
        Each Paying User acknowledges and agrees that, notwithstanding the fact that JELLY PLACES
        is not a party to the agreement between you and the Collecting User, JELLY PLACES acts as
        the Collecting User’s payment collection agent for the limited purpose of accepting payments
        from you on behalf of the Collecting User. Once a Paying User makes payment of funds to
        JELLY PLACES, the Paying User’s payment obligation to the Collecting User for the agreed
        upon amount is extinguished, and JELLY PLACES is responsible for remitting the funds to the
        Collecting User in the manner described in this Services Agreement, which constitutes JELLY
        PLACES’ agreement with the Collecting User. In the event that JELLY PLACES does not remit
        any funds, the Collecting User will have recourse only against JELLY PLACES and not the
        Paying User directly.
        <br/><br/>
        Each Collecting User agrees that payment made by a Paying User through JELLY PLACES
        shall be considered the same as a payment made directly to the Collecting User. The Collecting
        User will provide the purchased services to the Paying User in the agreed-upon manner as if
        the Collecting User has received the payment directly from the Paying User. Each Collecting
        User agrees that JELLY PLACES may refund the Paying User in accordance with this Services
        Agreement (including all applicable terms referenced herein) and each Collecting User
        understands that JELLY PLACES’ obligation to pay the Collecting User is subject to and
        conditional upon successful receipt of the associated payments from Paying User. JELLY
        PLACES shall be obligated to make payments to Collecting User only for such amounts that
        have been actually received by JELLY PLACES from Paying User in accordance with this
        Services Agreement. In accepting appointment as the limited payment collection agent of the
        Collecting User, JELLY PLACES assumes no liability for any acts or omissions of the Collecting
        User.
      </p>

      <h2>SECTION 3 - JELLY PLACES’ PLATFORM AND SERVICE</h2>
      <h2>3.1 Using the Platform</h2>
      <p>
        You are responsible for making all arrangements necessary for you to have access to the
        Platform and for ensuring that all persons who access the Platform through your Internet
        connection are aware of and abide by all applicable terms, including this Services Agreement.
        JELLY PLACES may require additional conditions or requirements to access and use the
        Platform, or specific areas or features of the Platform, at any time and for any reason. Access to
        or use of certain features of the Platform may be subject to additional policies or guidelines or
        may require you to accept additional terms and conditions. In the event of a conflict between
        the Services Agreement and the additional terms and conditions for a specific area of the
        Platform, the latter terms and conditions will take precedence with respect to that specific area
        of the Platform, unless otherwise specified.
      </p>

      <h2>3.2 Ownership, License, and Restrictions</h2>

      <p>
        <b>(a) License Grant.</b> Subject to the terms and conditions of this Services Agreement, JELLY
        PLACES hereby grants you a non-exclusive, non-transferable, non-sublicensable, nonassignable license,
        during the term of this Agreement, to use the Platform solely for the purpose
        of booking Spaces with Hosts. All rights not expressly granted to you are reserved by JELLY
        PLACES, and its licensors.
      </p>

      <p>
        <b>(b) Restrictions.</b> You may use the Platform and Services only for lawful purposes and in
        accordance with this Services Agreement. You shall not, and shall not allow any other party to:
        (a) license, sublicense, sell, resell, transfer, assign, distribute or otherwise provide or make
        available to any other party the Platform in any way; (b) modify or make derivative works based
        upon the Platform; (c) improperly use the Platform, including creating Internet "links" to any part
        of the Platform, "framing" or "mirroring" any part of the Platform; (d) reverse engineer,
        decompile, modify, or disassemble the Platform, except as allowed under applicable law; or (e)
        send spam or otherwise duplicative or unsolicited messages. In addition, you shall not, and
        shall not allow any other party to, access or use the Platform to: (i) design or develop a
        competitive or substantially similar product or service; (ii) copy or extract any features,
        functionality, or content thereof; (iii) launch or cause to be launched on or in connection with the
        Platform an automated program or script, including web spiders, crawlers, robots, indexers,
        bots, viruses or worms, or any program which may make multiple server requests per second,
        or unduly burden or hinder the operation and/or performance of the Platform; or (iv) attempt to
        gain unauthorized access to the Platform or its related systems or networks.
      </p>

      <p>
        <b>(c) Ownership.</b> The Platform and all rights therein are and shall remain JELLY PLACES’
        property or the property of JELLY PLACES’ licensors. Neither these Terms nor your use of the
        Platform convey or grant to you any rights: (i) in or related to the Platform except for the limited
        license granted above; or (ii) to use or reference in any manner JELLY PLACES’ company
        names, logos, product and service names, trademarks or services marks or those of JELLY
        PLACES’ licensors.
      </p>

      <h2>3.3 Content</h2>

      <p>
        <b>(a) Content on the Platform.</b> The materials displayed or available on or through the Platform,
        including but not limited to, text, graphics, data, a Space, a Host Service, information about
        yourself, articles, photos, images, illustrations, chat, and so forth (all the foregoing, the
        “Content”) are protected by copyright and/or other intellectual property laws. You promise to
        abide by all copyright notices, trademark rules, information, and restrictions contained in any
        Content you access through the Platform, and you will not use, copy, reproduce, modify,
        translate, publish, broadcast, transmit, distribute, perform, upload, display, license, sell or
        otherwise exploit for any purpose any Content not owned by you (i) without the prior consent of
        the owner of that Content, or (ii) in a way that violates someone else’s (including JELLY
        PLACES’) rights. You represent and warrant that you are authorized to provide Content to the
        Platform and that any Content you provide does not violate the proprietary or privacy rights of a
        third party. You grant JELLY PLACES a fully-paid, worldwide, non-exclusive, perpetual license
        to use, copy, transmit, distribute, modify, publicly display, and sublicense any Content you
        provide to us. This grant includes our ability to use any Content for both internal use (such as
        analysis to improve the Platform or Services) or external use (such as in marketing or online
        advertising). If you cannot provide us the above grant then you may not provide Content to us.
        You agree to indemnify, defend and hold us harmless for any damages or losses based on
        third-party claims that Content violates proprietary or privacy rights.
        <br/><br/>
        Any information or content publicly posted or privately transmitted through the Platform is the
        sole responsibility of the person from whom such content originated, and you access all such
        information and content at your own risk, and JELLY PLACES is not liable for any errors or
        omissions in that information or content or for any damages or loss you might suffer in
        connection
        with it. JELLY PLACES cannot control and has no duty to take any action regarding how you
        may interpret and use the Content or what actions you may take as a result of having been
        exposed to the Content, and you hereby release JELLY PLACES from all liability for you having
        acquired or not acquired Content through the Platform.
        <br/><br/>
        You are responsible for all Content you contribute, in any manner, to the Platform and you
        represent and warrant you have all rights necessary to do so, in the manner in which you
        contribute it. You may not upload, distribute or otherwise publish any Content that is invasive of
        privacy or publicity rights, infringing on intellectual property rights, unlawful, threatening,
        fraudulent, libelous, defamatory, obscene, vulgar, profane, abusive, harassing, hateful or
        otherwise objectionable, including but not limited to any Content that encourages conduct that
        would constitute a criminal offense or otherwise violates any applicable U.S. or foreign laws.
      </p>

      <p>
        <b>(b) Accuracy of Content.</b> You will only provide Content to us that you own or have
        authorization to provide, and ensure that Content is accurate and complete. You must keep any
        Content current. We reserve the right, but shall not be obligated, to use public and private data
        sources to validate the accuracy of any Content. This may include validating your identity or
        business information, or verifying information about Spaces. You will provide us any additional
        information to verify the accuracy or completeness of any Content that you provide and we may
        condition your use of the Platform or Service on our ability to verify the accuracy and
        completeness of this Content. If you do not provide required Content to us when we request it,
        we may suspend or terminate your Account, cancel any Bookings you have made or received,
        withhold payment, and/or impose and collect Fines.
      </p>

      <p>
        <b>(c) Third Party Content.</b> The services may contain links or connections to third party websites
        or services that are not owned or controlled by JELLY PLACES. When you access third party
        websites or use third party services, you accept that there are risks in doing so, and that JELLY
        PLACES is not responsible for such risks. JELLY PLACES has no control over, and assumes no
        responsibility for, the content, accuracy, privacy policies, or practices of or opinions expressed in
        any third-party websites or by any third party that you interact with through the Platform. In
        addition, JELLY PLACES will not and cannot monitor, verify, censor or edit the content of any
        third-party site or service. By using the Platform, you release and hold JELLY PLACES harmless
        from any and all liability arising from your use of any third-party website or service.
      </p>

      <p>
        <b>(d) Copyright.</b> We respect others’ intellectual property rights, and we reserve the right to delete
        or disable any content posted through the Platform that is alleged to be infringing, and to
        terminate the accounts of repeat alleged infringers, in accordance with the Digital Millennium
        Copyright Act (the "DMCA"). To report potentially infringing content, please e-mail us at [
        help@jellyplaces.com].
      </p>

      <p>
        <b>(e) Feedback.</b> We appreciate any ideas, suggestions, or feedback you voluntarily provide to
        help us improve the Platform or Services (“Feedback”). Any Feedback you provide us is ours to
        use, register, modify, monetize, and otherwise use. You grant us all title and interest in any
        Feedback you provide to us and, if necessary, agree to assist us in establishing our ownership.
        You acknowledge that you will not receive any compensation for providing u s Feedback.
      </p>

      <h2>3.4 Privacy</h2>
      <p>
        The collection, use, maintenance, disclosure and disposal of User Information, including the
        collection and use of your personally identifiable information, through the JELLY PLACES
        Platform is governed by our Privacy Policy which is incorporated into and is a part of these
        Terms.
      </p>

      <h2>SECTION 4 – JELLY PLACES SERVICES</h2>

      <h2>4.1 Terms Applicable to Hosts</h2>

      <p>
        <b>(a) Listings.</b> When you List a Space as a Host, you must provide all required details about the
        Space including a description, how it may be used, the cost of a Booking, availability, a list of
        any facilities, current pictures, rules, and other details about its potential uses and condition
        (collectively, “Description”). This includes details regarding use of any amenities, furniture,
        equipment, Host Add-Ons available at an additional charge, or fixtures that are part of or in the
        Space (collectively, “Amenities”). While it is important to communicate excitement and the
        benefits of your Space, the Description must be accurate and give potential Guests a
        reasonably good understanding of how they may use the Space for their Event, including the
        disclosure of any deficiencies, restrictions, or applicable policies. Any terms or conditions
        included in your Listing must not conflict with this Services Agreement or applicable policies
        unless expressly permitted by JELLY PLACES in writing. For example, you may not (i) modify
        or expand a Guests’ financial responsibilities or limit a Guests’ ability to seek recourse or
        restitution under this Services Agreement, or (ii) require Guests to pay you directly outside the
        Platform.
      </p>

      <p>
        <b>(b) Permission to List and Book Spaces.</b> You represent and warrant that you are permitted
        under applicable Laws to List and confirm a Booking of any Spaces provided, that Spaces
        comply with applicable Laws, and that you will reasonably facilitate and not obstruct the use of
        a Space as Booked by a Guest. In addition, you represent and warrant that any Listing you
        post, any Booking of a Space, or a Guest’s use of a Space will not breach any agreements you
        have entered into with any third parties, including but not limited to, homeowners associations,
        condominium associations, facilities managers, property managers, or other agreements, and
        will comply with all applicable laws, rules, regulations, restrictions (including having permits,
        licenses, and/or registrations), or other terms placed on the Space or use of the Space.
      </p>

      <p>
        <b>(c) Bookings.</b> Bookings are created when you accept a booking request, receive an
        acceptance to a custom offer you have submitted to a Guest, or enable your Listing to be
        booked without prior approval (“Instant Booking”) by a Guest. Bookings are a legally binding
        agreement between you and a Guest which means you are required to provide the Space and
        Host Services as described in your Listing and according to the terms agreed to in your
        Booking. You also agree to pay the applicable Service Fees to JELLY PLACES.
      </p>

      <p>
        <b>(d) Booking Price.</b> You are solely responsible for setting the Booking Price (including
        applicable Taxes, Cleaning Fees, and Hosts Services Fees). You may not raise the Booking
        Price for a particular Guest after the Guest accepts the Booking, but may alter prices based on
        the addition or deletion of Host Add-Ons prior to the Event. JELLY PLACES may determine, in
        its sole discretion, to refund the Guest part or all of the Booking Price in accordance with its
        policies.
      </p>

      <p>
        <b>(e) Conditions of the Space.</b> You are responsible for maintaining the Space and Amenities so
        that Guests may reasonably use them as provided in the Description and Booking. Spaces and
        Amenities must be in good working order and provided to Guests in a safe, clean and usable
        condition. Any plumbing, electrical, structural or other physical deficiencies or defects must be
        corrected or disclosed before you List a Space. Except as specified , all water, electricity, HVAC
        and other utility services (exclusive of telephone and data services) shall be provided with the
        Space at no additional cost. The condition of the Space must be accurate to its portrayal on your
        Listings.
      </p>

      <p>
        <b>(f) Booking Agreements.</b> In certain cases, we may elect to require a supplemental Booking
        Agreement with your Listing that includes all or part of the Description including a listing of any
        Amenities and any additional terms and conditions. Where required, the Booking Agreement will
        incorporate this Agreement and supplement and be a part of the binding agreement between
        you and a Guest. Except where approved by JELLY PLACES, Booking Agreements with
        supplemental clauses may not impose rules or limitations on use that are materially different
        than those listed in the Description, include any additional contractual obligations, or alter
        Guests’ or Hosts’ liabilities.
      </p>

      <p>
        <b>(g) Recordings.</b> During their Event, Guests may photograph, film, or otherwise record events
        when using Spaces and Amenities (“Event Recordings”). Except as expressly prohibited by
        federal, state, local or other governmental Laws or as otherwise set forth in the Description or
        any Booking Agreement, you grant to any Guest that Books a Space a fully-paid, worldwide,
        non
        exclusive, perpetual license to use, copy, transmit, distribute, modify, publicly display, and
        sublicense Event Recordings that may include or identify the Space or Amenities.
      </p>

      <p>
        <b>(h) Conduct and Fees.</b> As a Host, you are solely responsible for ensuring that Spaces and
        Amenities comply with all applicable Laws including any alcoholic beverage laws, local
        ordinances related to the condition, licensure, or registration of Spaces for use by Guests, and
        payment of Taxes. We may condition your continued use of the Platform and Services on your
        providing proof, to our reasonable satisfaction, of your compliance with Laws at any time. All
        Fees owed by Hosts are subject to the Fees Schedule and Cancellation and Refund Policy
        (located at _). Hosts are responsible for their own acts and omissions as well as the acts and
        omissions of any individuals who work or reside at or are otherwise present at a Space at your
        request or invitation, excluding the Guest and any individuals the Guest invites to the Space. If
        JELLY PLACES, in its sole discretion, determines that a Guest is entitled to receive a refund in
        accordance with this Services Agreement, the applicable policy set by JELLY PLACES, and/or
        the terms of the Booking Agreement between the Guest and Host mentioned in the Listing, after
        the Host has already been paid, JELLY PLACES will be entitled to recover the amount of any
        such refund from the Host, including by subtracting such refund amount out from any future
        payments due to the Host.
      </p>

      <p>
        <b>(i) Preparation for and Supervision of Event.</b> As a Host, you are solely responsible for (i)
        preserving and protecting your Space and Amenities by removing and/or securing valuable,
        vulnerable or sensitive items, (ii) determining the appropriate types and intensities of permitted
        uses in your Space, (iii) evaluating the appropriateness of potential Guests, and (iv) supervising
        and monitoring the Space and Amenities and your Guest’s use of the Space and Amenities for
        the Event, to the extent (if any) you as Host determine in your sole judgment.
      </p>

      <p>
        <b>(j) Provision of Alcoholic Beverages.</b> If a Host sells alcoholic beverages, the Host represents
        and warrants that: (a) it holds a valid, current alcoholic beverage license from the state and/or
        local licensing authorities that permits sales of alcohol directly to consumers; (b) that it currently
        complies and will comply with all alcoholic beverage laws; and (c) that it is responsible to
        alcoholic beverage authorities for activities or Bookings made on JELLY PLACES and any
        orders of alcoholic beverages under this Agreement. Host shall maintain all alcoholic beverage
        permits and licenses necessary and retain order records necessary to provide alcoholic
        beverage services as required by all applicable alcoholic beverage laws. Host acknowledges
        and agrees that orders will be fulfilled based on and under its legal rights under its alcoholic
        beverage licenses.
      </p>

      <h2>4.2 Terms Applicable to Guests</h2>

      <p>
        <b>(a) Bookings.</b> Bookings are created when a Host accepts your booking request or enables their
        listing to be booked without prior approval via the Platform. Bookings are a legally binding
        agreement between you and a Host and are subject to any additional terms and conditions that
        the Host has outlined in their listing description or via messaging, any supplemental Booking
        Agreements, and other terms set forth by the Host, which will be provided to you prior to
        confirmation of a Booking. A Booking creates a contractual relationship between users. Upon
        Booking a Space, a Guest is granted a limited, temporary, revocable license to use a Space.
        The scope of the use, including times, specific fees, and other additional terms or limitations,
        may also be included in or supplemented by a separate Booking Agreement that incorporates
        the terms and conditions of this Agreement and referenced policies.
      </p>

      <p>
        <b>(b) Booking Confirmation.</b> Guests must have a valid Account in order to complete Bookings.
        Bookings can be completed by following the Booking process for the applicable Space. Guests
        are encouraged to review the Space Description, applicable services descriptions, and confirm
        availability prior to Booking a Space, or Host Services. Some Spaces and services may require
        additional or supplemental verification in order to confirm a Booking. For example, Guests may
        be required to verify their age in order to Book certain Spaces or to purchase alcoholic
        beverages for an Event. Applicable Fees and other charges (e.g. a Deposit, if applicable) will be
        shown before you complete your Booking. You are responsible for all Fees, charges, and Taxes
        associated with the Booking. All Bookings are subject to our Cancellation and Refund Policy
        (located at _).
      </p>

      <p>
        <b>(c) Limited Licenses for Bookings.</b> When you Book a Space, you are provided a license to
        enter, occupy, and use the Space only as described in the Booking and confirmed by the Host,
        subject to this Services Agreement and any Booking Agreement. A Booking does not provide
        you a lease or access or use of the Space beyond the specified time and Description. Guests
        retain the right to re-enter the Space in accordance with your agreement with the Host.
      </p>

      <p>
        <b>(d) Conduct and Fees.</b> You agree to comply with any Booking Agreements throughout the
        Booking and use the Space only as permitted or agreed upon and consistent with the
        Description, and assure that any attendees do the same. During your Booking, you are
        responsible for (i) the behavior and acts of any attendees, service providers, or others that
        access the Space, (ii) ensuring that the use does not exceed any limitations identified in the
        Booking, (iii) coordinating the timely setup or breakdown of your Event, or (iv) complying with
        applicable Laws including acquiring any required licenses or permits for your Event, hiring
        security personnel for larger Events, or limiting noise to certain times of the day. You are
        responsible for and accept all liability for any damage done to the Space or Amenities during
        your Event by attendees or service providers whether intentional or not, for your failure to
        comply with applicable Laws, and for any Fines you incur. You agree to return the Space to the
        Host in substantially the condition as provided to you or as otherwise agreed in any Booking
        Agreement, and to promptly notify Hosts of any damage done to the Space or Amenities. All
        Fees owed by Guests are subject to the Fees Schedule and the Cancellation and Refund Policy
        (located at _).
      </p>

      <p>
        <b>(e) Cancellation and Refunds.</b> All cancellations and any refunds that may be available to you
        are subject to our Fees Schedule (located at _).
      </p>

      <p>
        <b>(f) Holdovers and Additional Fees.</b> You agree to leave the Space no later than the end-time of
        your Event or at such other time as mutually agreed upon between you and the Host. If you stay
        past the agreed upon end-time without the Host’s permission, you no longer have a license to
        use or stay in the Space and the Host is entitled to use lawful means to force you to leave the
        Space. Additionally, you agree to pay to Host an overtime charge (“Overtime Charge”) as well
        as all applicable Guest Fees, Taxes, and other expenses incurred by the Host. We may
        calculate the Overtime Charges based on your exceeding the time (for example, a calculated
        hourly rate billed
        in 30-minute increments). You may also be charged for other costs associated with your
        exceeding the permitted usage of the Booking (for example, exceeding the number of Event
        attendees (“Over Capacity Fees”). JELLY PLACES may charge an additional Service Fee for
        any Overtime Charges, Damage Charges, or any other charges assessed by Host using the
        Platform. You may read more about all fees and charged in our Fees Schedule (located at
        [https://github.com/Jelly-Places/site-policy/blob/master/Policies/fee-schedule.md]). If JELLY
        PLACES, in its sole discretion, determines that a Host is entitled to additional fees, charges, or
        damages in accordance with this Services Agreement, the applicable policy set by JELLY
        PLACES, and/or the terms of the Booking Agreement between the Guest and Host mentioned in
        the Listing, JELLY PLACES will be entitled to collect any such sums from you required to satisfy
        the outstanding obligation. JELLY PLACES reserves the right to otherwise collect payment from
        you and pursue any remedies available to JELLY PLACES in this regard in situations in which
        you are responsible for fees, charges, or damages, including, but not limited to, in relation to any
        payment requests made by Hosts.
      </p>

      <p>
        <b>(g) Using Host Services.</b> You are responsible for evaluating the description, costs, restrictions,
        and applicable terms for any Host Services provided by the Host in connection with a Booking.
        You are responsible for identifying and complying with all laws, rules, regulations, and
        restrictions applicable to your use of any Host Services. We are not responsible for and
        disclaim all responsibility for and liability resulting from Hosts’ actions or failure to act, for the
        nature or quality of any Host Services, and for any loss or injury you or others sustain arising
        out of or related to Host Services.
      </p>

      <p>
        <b>(h) Alcoholic Beverages.</b> JELLY PLACES does not produce, distribute or sell alcoholic
        beverages, but may relay order information to licensed retailers and/or manufacturers
        (“Licensees”) who may provide for the sale of alcoholic beverages, either as: (i) a Host listing
        Spaces where alcoholic beverages are sold; or (ii) a Host offering Host Services. Neither
        Licensees nor JELLY PLACES sell alcohol to persons under the age of 21. By using this
        Platform for Services related to alcoholic beverages you acknowledge, affirm, and represent to
        us that you are over the legal age required to buy alcoholic beverages. We make every effort to
        ensure that alcoholic beverages are not offered, sold, served, or delivered to anyone who is
        under the age of 21. By using this Platform, you are acknowledging that the person receiving
        any shipment of alcoholic beverages from a Licensee is over the age of 21. You also agree that
        any alcohol purchased from a Licensee is intended for personal consumption and not for resale.
        If you cannot make these affirmations and representations or you do not agree with all of these
        conditions of use, you may not use the Platform and Services. If you misrepresent your age in
        order to obtain or provide alcohol to a person under the age of 21, we will report this to the
        appropriate authorities for prosecution to the fullest extent allowable by law. If we are held liable
        for any reason based on a misrepresentation of your age, you agree to indemnify us and/or
        reimburse us for all costs, expenses (including legal fees) and damages we and they suffer or
        incur.
      </p>

      <p>
        <b>(i) Attendees.</b> You are responsible for your own acts and omissions as well as the acts and
        omissions of your invitees or other individuals to whom you provide access to the Space
        (collectively referred to as “Attendees”), excluding the Host and the Host’s invitees, if applicable.
        Guests are required to ensure that Attendees meet any requirements set by the Host for a
        Space or Event, and are made aware of and agree to any terms, conditions, rules, policies, or
        restrictions set by Host. You agree that you are responsible for ensuring that all minors are
        accompanied by an adult responsible for them and that you are legally authorized to act on
        behalf of any minor Invitee.
      </p>

      <h2>4.3 Insurance</h2>

      <p>
        You shall acquire and maintain all insurance as required by Law and suitable for you or your
        business. You are solely responsible for understanding and evaluating what insurance is
        appropriate to cover damage, loss, injury, legal liability, and other harm specific to you, your
        business, your Attendees, third parties, the Space (including personal property within), and
        deciding what type of coverage, limits and providers are appropriate for you.
      </p>

      <h2>4.4 Damage and Cleaning Fees</h2>

      <p>
        <b>(a) Damages to Spaces.</b> Guests are responsible for any damages that a Guest or his/her/its
        Attendees cause to a Space or any personal property at a Space. Hosts shall report any
        damages along with relevant documentation (“Damages Claim”), to the Guest within 48 hours
        of the Event end time. The Guest and Host shall be responsible for resolving such Damages
        Claim between themselves and hold JELLY PLACES harmless from any and all claims related
        to such Damages Claim.
      </p>

      <p>
        <b>(b) Cleaning Fees.</b> While a Host may charge a Cleaning Fee and is responsible for basic
        upkeep and removal of trash, Guests are encouraged to tidy up and be respectful of the space
        and personal belongings during the booking. Cleaning Fees may be assessed regardless of the
        wear and tear placed on a Space (or the personal property within it) during an Event.
      </p>

      <h2>SECTION 5 – REPRESENTATIONS AND WARRANTIES; DISCLAIMERS</h2>

      <p>
        <b>5.1 By You.</b> You hereby represent and warrant that: (a) you have full power and authority to
        enter into this Services Agreement and perform your obligations hereunder; (b) you have not
        entered into, and during the term will not enter into, any agreement that would prevent you from
        complying with this Services Agreement; (c) you will comply with all applicable laws in your
        performance of this Services Agreement, including holding and complying with all permits,
        licenses, registrations and other governmental authorizations necessary to provide Spaces
        pursuant to this Services Agreement; and (d) you will not allow any third party to use your
        account.
      </p>

      <p>
        <b>5.2 Disclaimer of Warranties.</b> YOUR USE OF THE PLATFORM, INCLUDING ANY CONTENT
        OR INFORMATION CONTAINED WITHIN THE PLATFORM, ANY PLATFORM-RELATED
        SERVICE OR SOFTWARE THAT IS PROVIDED TO YOU, IS AT YOUR SOLE RISK. THE
        PLATFORM, INCLUDING ANY CONTENT, SOFTWARE OR INFORMATION CONTAINED
        WITHIN THE PLATFORM AND ANY PLATFORM-RELATED SERVICE, IS PROVIDED ON AN
        "AS IS" AND "AS AVAILABLE" BASIS. WE AND OUR SUPPLIERS, LICENSORS, AND OTHER
        RELATED PARTIES, AND THEIR RESPECTIVE OFFICERS, AGENTS, REPRESENTATIVES,
        AND EMPLOYEES, EXPRESSLY DISCLAIM ANY AND ALL REPRESENTATIONS OR
        WARRANTIES OF ANY KIND, WHETHER EXPRESS, STATUTORY OR IMPLIED,
        INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY,
        FITNESS FOR A PARTICULAR PURPOSE, TITLE, ACCURACY OF DATA, SATISFACTORY
        QUALITY, AND NON-INFRINGEMENT. BECAUSE SOME JURISDICTIONS MAY NOT
        PERMIT THE EXCLUSION OF CERTAIN WARRANTIES, SOME OF THESE EXCLUSIONS
        MAY NOT APPLY TO YOU. JELLY PLACES DOES NOT REPRESENT, WARRANT OR
        GUARANTEE THAT YOUR ACCESS TO OR USE OF THE PLATFORM: (A) WILL BE
        UNINTERRUPTED OR ERROR FREE; OR (B) WILL RESULT IN ANY REQUESTS FOR
        SPACES. JELLY PLACES FUNCTIONS AS A LEAD GENERATION AND RELATED SERVICE
        ONLY AND MAKES NO REPRESENTATIONS, WARRANTIES OR GUARANTEES AS TO
        THE ACTIONS OR INACTIONS OF THE USERS WHO MAY REQUEST OR RECEIVE
        SPACES FROM YOU, AND JELLY PLACES DOES NOT SCREEN OR OTHERWISE
        EVALUATE USERS. BY USING THE PLATFORM, YOU ACKNOWLEDGE AND AGREE THAT
        YOU MAY BE INTRODUCED TO A
        THIRD PARTY THAT MAY POSE HARM OR RISK TO YOU OR OTHER THIRD PARTIES.
        YOU ARE ADVISED TO TAKE REASONABLE PRECAUTIONS WITH RESPECT TO
        INTERACTIONS WITH THIRD PARTIES ENCOUNTERED IN CONNECTION WITH THE USE
        OF THE PLATFORM. NOTWITHSTANDING JELLY PLACES’ APPOINTMENT AS THE
        LIMITED PAYMENT COLLECTION AGENT OF YOU FOR THE PURPOSE OF ACCEPTING
        PAYMENT FROM USERS ON YOUR BEHALF AS SET FORTH IN SECTION 2.3(g) ABOVE,
        JELLY PLACES EXPRESSLY DISCLAIMS ALL LIABILITY FOR ANY ACT OR OMISSION OF
        YOU, ANY USER OR OTHER THIRD PARTY.
      </p>

      <p>
        <b>5.3 No Service Guarantee.</b> JELLY PLACES DOES NOT GUARANTEE THE AVAILABILITY
        OR UPTIME OF THE JELLY PLACES PLATFORM. YOU ACKNOWLEDGE AND AGREE
        THAT THE PLATFORM MAY BE UNAVAILABLE AT ANY TIME AND FOR ANY REASON
        (e.g., DUE TO SCHEDULED MAINTENANCE OR NETWORK FAILURE). FURTHER, THE
        PLATFORM MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS
        INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS, AND
        JELLY PLACES IS NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES, OR
        OTHER DAMAGES, LIABILITIES OR LOSSES RESULTING FROM SUCH PROBLEMS.
      </p>

      <h2>SECTION 6 – INDEMNIFICATION</h2>

      <p>
        You shall indemnify, defend (at JELLY PLACES’s option) and hold harmless JELLY PLACES,
        its affiliates, and each of the foregoing’s respective officers, directors, employees, agents,
        successors and assigns from and against any and all claims, liabilities, expenses (including
        legal fees and costs), damages, penalties, fines, social contributions and taxes arising out of or
        related to (a) your use of the Platform (including any actions taken by a third party using your
        account or identity in the Platform); (b) your provision of Space (for Hosts), or use of Spaces
        (for Guests); (c) any material or any other content or information that You submit, post or
        upload to or transmit through the Platform; and (d) your violation of this Services Agreement.
        These obligations will survive any termination of your relationship with us or your use of the
        Platform. We reserve the right to assume the defense and control of any matter subject to
        indemnification by you, in which event you will cooperate with us in asserting any available
        defenses.
      </p>

      <h2>SECTION 7 – LIMITATION OF LIABILITY</h2>

      <p>
        IN NO EVENT SHALL JELLY PLACES OR JELLY PLACES’ EMPLOYEES, OFFICERS,
        REPRESENTATIVES, SUPPLIERS, LICENSORS, AND AGENTS BE LIABLE FOR ANY
        DIRECT, SPECIAL, INDIRECT, INCIDENTAL, EXEMPLARY, PUNITIVE OR
        CONSEQUENTIAL DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING,
        BUT NOT LIMITED TO, LOSS OF USE, LOSS OF PROFITS, OR LOSS OF DATA, WHETHER
        IN AN ACTION IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE),
        OR OTHERWISE, ARISING OUT OF OR IN ANY WAY CONNECTED WITH: (i) THE USE OR
        INABILITY TO USE THE PLATFORM OR THE CONTENT, MATERIALS, SOFTWARE,
        INFORMATION OR TRANSACTIONS PROVIDED ON OR THROUGH THE PLATFORM; (ii)
        ANY CLAIM ATTRIBUTABLE TO ERRORS, OMISSIONS, OR OTHER INACCURACIES IN
        THE PLATFORM OR THE CONTENT, MATERIALS, SOFTWARE, INFORMATION,
        PRODUCTS, OR SERVICES ON OR AVAILABLE THROUGH THE PLATFORM; (iii) THE
        COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM
        ANY PRODUCTS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR
        MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE
        PLATFORM; (iv) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR
        TRANSMISSIONS OR DATA; (v) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON
        THE PLATFORM; (vi) THE DELAY
        OR FAILURE IN PERFORMANCE RESULTING FROM AN ACT OF FORCE MAJEURE,
        INCLUDING, WITHOUT LIMITATION, ACTS OF GOD, NATURAL DISASTERS,
        COMMUNICATIONS FAILURE, GOVERNMENTAL ACTIONS, WARS, STRIKES, LABOR
        DISPUTES, RIOTS, SHORTAGES OF LABOR OR MATERIALS, VANDALISM, TERRORISM,
        NON-PERFORMANCE OF THIRD PARTIES OR ANY REASONS BEYOND THEIR
        REASONABLE CONTROL; OR (vii) ANY OTHER MATTER RELATING TO THE PLATFORM,
        EVEN IF WE OR OUR AUTHORIZED REPRESENTATIVES HAVE BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES. YOUR SOLE REMEDY FOR DISSATISFACTION WITH
        THE PLATFORM OR PLATFORM-RELATED SERVICES IS TO STOP USING THE
        PLATFORM AND/OR THOSE SERVICES. WE ARE NOT RESPONSIBLE OR LIABLE FOR
        YOUR ILLEGAL, UNAUTHORIZED, OR IMPROPER USE OF INFORMATION TRANSMITTED,
        MONITORED, STORED, OR RECEIVED USING THE PLATFORM. THIS LIMITATION OF
        LIABILITY INCLUDES ANY OF THE FOREGOING TYPES OF DAMAGES ARISING FROM
        YOUR ACCESS OR USE OF THE PLATFORM OR ANY SPACES PROVIDED (FOR HOSTS)
        OR USE OF SPACES (FOR GUESTS).
        <br/><br/>
        APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OF LIABILITY, IMPLIED
        WARRANTIES, OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES SET FORTH
        ABOVE, SO THIS LIMITATION OF LIABILITY MAY NOT APPLY TO YOU. IF ANY PART OF
        THIS LIMITATION ON LIABILITY IS FOUND TO BE INVALID OR UNENFORCEABLE FOR
        ANY REASON, THEN THE AGGREGATE LIABILITY OF US UNDER SUCH
        CIRCUMSTANCES FOR LIABILITIES THAT OTHERWISE WOULD HAVE BEEN LIMITED
        SHALL NOT EXCEED TWO HUNDRED U.S. DOLLARS ($200.00).
      </p>

      <h2>SECTION 8 – TERM AND TERMINATION</h2>

      <p>
        You shall indemnify, defend (at JELLY PLACES’ option) and hold harmless JELLY PLACES and
        its affiliates and their respective officers, directors, employees, agents, successors and assigns
        from and against any and all liabilities, expenses (including legal fees and costs), damages,
        penalties, fines, social contributions and taxes arising out of or related to: (a) your breach of your
        representations, warranties or obligations under this Services Agreement; or (b) a claim by a
        third party (including Users, regulators and governmental authorities) directly or indirectly
        related to your provision of Spaces (for Hosts) or use of Spaces (for Guests), or use of the
        JELLY PLACES Platform.
      </p>

      <p>
        <b>8.1 Term.</b> This Agreement shall commence on the date accepted by you and shall continue until
        terminated as set forth herein.
      </p>

      <p>
        <b>8.2 Termination.</b> Either party may terminate this Agreement by deleting and removing the
        JELLY PLACES Platform. In addition, JELLY PLACES may terminate this Agreement or
        deactivate your Account immediately, without notice, with respect to you in the event you no
        longer qualify, under applicable law or the standards and policies of JELLY PLACES, to provide
        Spaces (for Hosts) or use Spaces (for Guests), or as otherwise set forth in this Agreement.
      </p>

      <p>
        <b>8.3 Effect of Termination.</b> Upon termination of the Agreement, you shall immediately delete
        and fully remove the JELLY PLACES Platform from any of your Devices and cease providing
        Spaces (for Hosts) or use Spaces (for Guests) via the Platform. Outstanding payment
        obligations shall survive the termination of this Agreement.
      </p>

      <h2>SECTION 9 – RELATIONSHIP OF THE PARTIES</h2>

      <p>
        Except as otherwise expressly provided herein with respect to JELLY PLACES acting as the
        limited payment collection agent solely for the purpose of collecting payment, the relationship
        between the parties under this Services Agreement is solely that of independent contractors.
        The parties expressly agree that: (a) this Agreement is not an employment agreement, nor
        does it create an employment relationship, between JELLY PLACES and you; and (b) no joint
        venture, partnership, or agency relationship exists between JELLY PLACES and you.
      </p>

      <p>
        You have no authority to bind JELLY PLACES and you undertake not to hold yourself out as an
        employee, agent or authorized representative of JELLY PLACES. Where, by implication of
        mandatory law or otherwise, you may be deemed an agent or representative of JELLY PLACES,
        you undertake and agree to indemnify, defend (at JELLY PLACES’ option) and hold I JELLY
        PLACES and its affiliates harmless from and against any claims by any person or entity based
        on such implied agency or representative relationship.
      </p>

      <h2>SECTION 10 – APPLE APP STORE TERMS</h2>

      <p>
        This Agreement applies to your use of the Platform, including the iPhone, and iPad applications
        available via the Apple, Inc. ("Apple") App Store (the "Application"), but the following additional
        terms also apply to the Application:
      </p>

      <p>
        <b>10.1</b> Both you and JELLY PLACES acknowledge that this Agreement is concluded between you
        and JELLY PLACES only, and not with Apple, and that Apple is not responsible for the
        Application or any content posted to or made available via the Application;
      </p>

      <p>
        <b>10.2</b> The Application is licensed to you on a limited, non-exclusive, non-transferrable, non
        sublicensable basis, solely to be used in connection with the JELLY PLACES Platform for your
        private, personal, non-commercial use, subject to all the terms and conditions of this Agreement
        as it is applicable to the Platform;
      </p>

      <p>
        <b>10.3</b> You will only use the Application in connection with an Apple device that you own or
        control;
      </p>

      <p>
        <b>10.4</b> You acknowledge and agree that Apple has no obligation whatsoever to furnish any
        maintenance and support services with respect to the Application;
      </p>

      <p>
        <b>10.5</b> In the event of any failure of the Application to conform to any applicable warranty,
        including those implied by law, you may notify Apple of such failure; upon notification, Apple’s
        sole warranty obligation to you will be to refund to you the purchase price, if any, of the
        Application;
      </p>

      <p>
        <b>10.6</b> You acknowledge and agree that JELLY PLACES, and not Apple, is responsible for
        addressing any claims you or any third party may have in relation to the Application;
      </p>

      <p>
        <b>10.7</b> You acknowledge and agree that, in the event of any third party claim that the Application
        or your possession and use of the Application infringes that third party’s intellectual property
        rights, JELLY PLACES, and not Apple, will be responsible for the investigation, defense,
        settlement and discharge of any such infringement claim;
      </p>

      <p>
        <b>10.8</b> You represent and warrant that you are not located in a country subject to a U.S.
        Government embargo, or that has been designated by the U.S. Government as a "terrorist
        supporting" country, and that you are not listed on any U.S. Government list of prohibited or
        restricted parties;
      </p>

      <p>
        <b>10.9</b> Both you and JELLY PLACES acknowledge and agree that, in your use of the Application,
        you will comply with any applicable third party terms of agreement which may affect or be
        affected by such use; and
      </p>

      <p>
        <b>10.10</b> Both you and JELLY PLACES acknowledge and agree that Apple and Apple’s
        subsidiaries are third party beneficiaries of this Agreement, and that upon your acceptance of
        these Terms, Apple will have the right (and will be deemed to have accepted the right) to
        enforce this Agreement against you as the third party beneficiary hereof.
      </p>

      <h2>SECTION 11 – DISPUTES; BINDING ARBITRATION</h2>

      <p>
        <b>11.1 Arbitration.</b> You agree that any dispute, claim or controversy arising out of or relating to
        these Terms, Services Agreement, or Privacy Policy or the breach, termination, enforcement,
        interpretation or validity thereof or the use of the Platform (collectively, "Disputes") will be settled
        by final and binding arbitration between you and JELLY PLACES. This Arbitration Agreement is
        governed by the Federal Arbitration Act (9 U.S.C. §§ 1-16). Notwithstanding anything else
        contained herein, each party retains the right to bring an individual action in small claims court
        and the right to seek injunctive or other equitable relief in a court of competent jurisdiction to
        prevent the actual or threatened infringement, misappropriation or violation of a party's
        copyrights, trademarks, trade secrets, patents or other intellectual property rights. You
        acknowledge and agree that you and JELLY PLACES are each waiving the right to a trial by
        jury or to participate as a plaintiff or class in any purported class action or representative
        proceeding. Further, unless both you and JELLY PLACES otherwise agree in writing, the
        arbitrator may not consolidate more than one person's claims and may not otherwise preside
        over any form of any class or representative proceeding. If this specific paragraph is held
        unenforceable, then the entirety of this "Arbitration Agreement" section will be deemed void.
        Except as provided in the preceding sentence, this "Arbitration Agreement" section will survive
        any termination of these Terms.
        <br/><br/>
        Except as it otherwise provides, this Arbitration Agreement also applies, without limitation, to
        disputes arising out of or related to this Agreement and disputes arising out of or related to your
        relationship with JELLY PLACES, including termination of the relationship. This Arbitration
        Agreement also applies, without limitation, to disputes regarding any city, county, state or
        federal wage-hour law, trade secrets, unfair competition, compensation, breaks and rest
        periods, expense reimbursement, termination, harassment and claims arising under the
        Uniform Trade Secrets Act, Civil Rights Act of 1964, Americans With Disabilities Act, Age
        Discrimination in Employment Act, Family Medical Leave Act, Fair Labor Standards Act,
        Employee Retirement Income Security Act (except for claims for employee benefits under any
        benefit plan sponsored by IJELLY PLACES and covered by the Employee Retirement Income
        Security Act of 1974 or funded by insurance), Genetic Information Non-Discrimination Act, and
        state statutes, if any, addressing the same or similar subject matters, and all other similar
        federal and state statutory and common law claims.
      </p>

      <p>
        <b>11.2 Arbitration Rules and Governing Law.</b> The arbitration will be administered by the
        American Arbitration Association ("AAA") in accordance with the AAA’s Commercial Arbitration
        Rules (the "AAA Rules") then in effect, except as modified by this Arbitration Agreement.
        <br/><br/>
        The parties agree that the arbitrator (“Arbitrator”), and not any federal, state, or local court or
        agency, shall have exclusive authority to resolve any disputes relating to the interpretation,
        applicability, enforceability or formation of this Arbitration Agreement, including any claim that all
        or any part of this Arbitration Agreement is void or voidable. The Arbitrator shall also be
        responsible for determining all threshold arbitrability issues, including issues relating to whether
        the Terms are unconscionable or illusory and any defense to arbitration, including waiver, delay,
        laches, or estoppel.
        <br/><br/>
        The arbitration shall be heard by one arbitrator selected in accordance with the AAA Rules. The
        arbitrator shall be a licensed attorney or retired judge with experience in the law underlying the
        dispute.
      </p>

      <p>
        <b>11.3 Arbitration Process.</b> If either party wishes to initiate arbitration, the initiating party must
        notify the other party in writing via certified mail, return receipt requested, or hand delivery within
        the applicable statute of limitations period. This demand for arbitration must include (1) the
        name and address of the party seeking arbitration, (2) a statement of the legal and factual basis
        of the claim, and (3) a description of the remedy sought.
      </p>

      <p>
        <b>11.4 Arbitration Location and Procedure.</b> The arbitration shall take place in Miami-Dade
        County, Florida. If your claim does not exceed $10,000, then the arbitration will be conducted
        solely on the basis of documents you and JELLY PLACES submit to the arbitrator, unless you
        request a hearing, or the arbitrator determines that a hearing is necessary. If your claim exceeds
        $10,000, your right to a hearing will be determined by the AAA Rules. Subject to the AAA Rules,
        the arbitrator will have the discretion to direct a reasonable exchange of information by the
        parties, consistent with the expedited nature of the arbitration.
      </p>

      <p>
        <b>11.5 Arbitrator's Decision.</b> The arbitrator will render an award within the time frame specified
        in the AAA Rules. The arbitrator's decision will include the essential findings and conclusions
        upon which the arbitrator based the award. Judgment on the arbitration award may be entered
        in any court having jurisdiction thereof. The arbitrator's award damages must be consistent with
        the terms of the "Limitation of Liability" section above as to the types and the amounts of
        damages for which a party may be held liable. The arbitrator may award declaratory or
        injunctive relief only in favor of the claimant and only to the extent necessary to provide relief
        warranted by the claimant's individual claim. If you prevail in arbitration you will be entitled to an
        award of attorneys' fees and expenses, to the extent provided under applicable law.
      </p>

      <p>
        <b>11.6 Fees.</b> The party initiating the arbitration must bear the costs and fees related thereto.
      </p>

      <p>
        <b>11.7 Severability and Survival.</b> This arbitration agreement is the full and complete agreement
        relating to the formal resolution of disputes covered by this arbitration agreement. In the event
        any portion of this arbitration agreement is deemed unenforceable, the remainder of this
        arbitration agreement will be enforceable. Therefore, terms of this Arbitration Agreement shall
        be severed, to the smallest extent possible, if required to uphold the enforceability of this
        Arbitration Agreement. The Arbitration Agreement is a contractual agreement to mutually
        arbitrate claims.
      </p>

      <h2>SECTION 12 – MISCELLANEOUS TERMS</h2>

      <p>
        <b>12.1 Modification.</b> JELLY PLACES reserves the right to modify the terms and conditions of this
        Agreement at any time, effective upon publishing an updated version of this Agreement on the
        portal available to you on the Platform. JELLY PLACES reserves the right to modify any
        information referenced at hyperlinks from this Services Agreement from time to time. You
        hereby acknowledge and agree that, by using the Platform, or downloading, installing or using
        the Platform, you are bound by any future amendments and additions to this Services
        Agreement, information referenced at hyperlinks herein, or documents incorporated herein,
        including with respect to Fees Schedule. Continued use of the Platform after any such changes
        shall constitute your consent to such changes.
      </p>

      <p>
        <b>12.2 Supplemental Terms.</b> Supplemental terms may apply to your use of the Platform, such as
        use policies or terms related to certain features and functionality, which may be modified from
        time to time ("Supplemental Terms"). You may be presented with certain Supplemental Terms
        from time to time. Supplemental Terms are in addition to, and shall be deemed a part of, this
        Services Agreement. Supplemental Terms shall prevail over this Services Agreement in the
        event of a conflict.
      </p>

      <p>
        <b>12.3 Severability.</b> If any provision of this Agreement is or becomes invalid or non-binding, the
        parties shall remain bound by all other provisions hereof. In that event, the parties shall replace
        the invalid or non-binding provision with provisions that are valid and binding and that have, to
        the greatest extent possible, a similar effect as the invalid or non-binding provision, given the
        contents and purpose of this Agreement.
      </p>

      <p>
        <b>12.4 Assignment.</b> You may not assign, delegate or transfer this Agreement or your rights or
        obligations hereunder, or your account with the Platform, in any way (by operation of law or
        otherwise) without JELLY PLACES’ prior written consent. JELLY PLACES may transfer, assign,
        or delegate this Agreement and its rights and obligations without consent.
      </p>

      <p>
        <b>12.5 Entire Agreement.</b> This Agreement, including all Supplemental Terms, constitutes the
        entire agreement and understanding of the parties with respect to its subject matter and
        replaces and supersedes all prior or contemporaneous agreements or undertakings regarding
        such subject matter. In this Agreement, the words "including" and "include" mean "including, but
        not limited to."
      </p>

      <p>
        <b>12.6 No Third-Party Beneficiaries.</b> Except as expressly set forth in Section 10 above regarding
        the Apple Application, there are no third-party beneficiaries to this Agreement and nothing
        contained in this Agreement is intended to or shall be interpreted to create any third-party
        beneficiary claims.
      </p>

      <p>
        <b>12.7 Notices.</b> JELLY PLACES may give notice by means of a general notice on the Platform,
        electronic mail to your email address in your Account, or by written communication sent by first
        class mail or pre-paid post to your address in your Account. Such notice shall be deemed to
        have been given upon the expiration of 48 hours after mailing or posting (if sent by first class
        mail or pre-paid post) or 12 hours after sending (if sent by email). You may give notice to JELLY
        PLACES, with such notice deemed given when received by JELLY PLACES, at any time by first
        class mail, pre-paid post, or electronic mail to JELLY PLACES, 75 Miracle Mile, PO BOX
        348564, Coral Gables, FL 33134 or help@jellyplaces.com.
      </p>

      <p>
        <b>12.8 Choice of Law.</b> This Agreement is governed by and construed in accordance with the laws
        of the State of Florida, U.S.A., without giving effect to any conflict of law principles. For all claims
        not subject to the Arbitration Agreement in Section 11, you agree that they will be brought
        exclusively in the federal or state courts of competent jurisdiction sitting in Miami-Dade County,
        Florida, and you expressly agree that such courts shall have jurisdiction over you. The prevailing
        party in any dispute arising hereunder or from your use of the Platform shall be entitled to an
        award of its reasonable attorney’s fees and legal costs against the non-prevailing party.
      </p>

      <p>
        TO THE EXTENT PERMITTED BY APPLICABLE LAWS, YOU AND JELLY PLACES AGREE
        THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE SITES MUST
        COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES.
        OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
      </p>
    </div>
  );
};
TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};
const { string } = PropTypes;
TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};
export default TermsOfService;
