/*
 * Marketplace specific configuration.
 */
export const amenities = [
  {
    key: 'accessible',
    label: 'Accessible Place',
  },
  {
    key: 'air_conditioning',
    label: 'Air Conditioning',
  },
  {
    key: 'bathroom',
    label: 'Bathroom',
  },
  {
    key: 'beverage_coffee',
    label: 'Coffee',
  },
  {
    key: 'beverage_water',
    label: 'Water',
  },
  {
    key: 'elevator',
    label: 'Elevator',
  },
  {
    key: 'internet_wifi',
    label: 'Internet (WiFi)',
  },
  {
    key: 'internet_wired',
    label: 'Internet (Wired)',
  },
  {
    key: 'telephone',
    label: 'Telephone',
  },
  {
    key: 'mentorship',
    label: 'Mentorship',
  },
  {
    key: 'parking_free',
    label: 'Parking',
  },
  {
    key: 'printer',
    label: 'Printer',
  },
  {
    key: 'product_samples',
    label: 'Product Samples*',
  },
  {
    key: 'pro_networking',
    label: 'Professional Networking',
  },
  {
    key: 'projector',
    label: 'Projector',
  },
  {
    key: 'video_conferencing',
    label: 'Video Conferencing',
  },
];
export const categories = [
  {
    key: 'art_fashion_design',
    label: 'Art, Fashion & Design',
  },
  {
    key: 'financial_services',
    label: 'Co-working & Services',
  },
  {
    key: 'food_beverage',
    label: 'Food & Beverage',
  },
  {
    key: 'government_education',
    label: 'Government & Education',
  },
  {
    key: 'health_medical',
    label: 'Health & Wellness',
  },
  {
    key: 'hospitality_services',
    label: 'Hospitality & Services',
  },
  {
    key: 'legal_services',
    label: 'Legal & Services',
  },
  {
    key: 'media_advertising',
    label: 'Media & Advertising',
  },
  {
    key: 'real_estate_services',
    label: 'Real Estate & Services',
  },
  {
    key: 'science_technology',
    label: 'Science & Technology',
  },
  {
    key: 'sports_entertainment',
    label: 'Sports & Entertainment',
  },
  {
    key: 'transportation',
    label: 'Transportation',
  },
  {
    key: 'other',
    label: 'Other',
  },
];

export const jellyTypes = [
  /*{ key: 'day_pass', label: 'Get Jelly Day Pass' },*/
  { key: 'seat', label: 'Get Jelly Seat' },
  { key: 'desk', label: 'Get Jelly Desk' },
  { key: 'office', label: 'Get Jelly Office' },
  { key: 'conference_room', label: 'Get Jelly Conference Room' },
  { key: 'classroom', label: 'Get Jelly Classroom' },
  { key: 'auditorium', label: 'Get Jelly Auditorium' },
  { key: 'event', label: 'Get Jelly Event' },
  { key: 'ride_along', label: 'Get Jelly Ride Along' },
];
// Price filter configuration
// Note: unlike most prices this is not handled in subunits
export const priceFilterConfig = {
  min: 0,
  max: 1000,
  step: 5,
};
// Activate booking dates filter on search page
export const dateRangeFilterConfig = {
  active: true,
};
// Activate keyword filter on search page
// NOTE: If you are ordering search results by distance the keyword search can't be used at the same time.
// You can turn off ordering by distance in config.js file
export const keywordFilterConfig = {
  active: true,
};
