import React from 'react';
import PropTypes from 'prop-types';

const IconLogo = props => {
  const { className, ...rest } = props;

  return (
    <svg
      className={className}
      {...rest}
      width="21"
      height="25"
      viewBox="0 0 21 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path className="st0" d="M15.84,6.16c-0.45-2.47-2.8-4.11-5.27-3.68c1.69-0.29,3.31,0.84,3.61,2.53c0.31,1.7-0.82,3.34-2.53,3.64
			c-1.7,0.31-3.34-0.82-3.64-2.53C7.7,4.43,8.82,2.81,10.51,2.49c-2.46,0.46-4.09,2.83-3.64,5.3c0.45,2.48,2.82,4.13,5.3,3.68
			C14.65,11.01,16.29,8.64,15.84,6.16z"/>
          <path className="st0" d="M10.57,2.48c-0.01,0-0.02,0-0.03,0s-0.02,0.01-0.03,0.01c0.01,0,0.02,0,0.03-0.01
			C10.55,2.48,10.56,2.48,10.57,2.48z"/>
        </g>
        <polygon className="st1" points="8.49,10.49 0.15,23.41 7.39,9.23 	"/>
        <polygon className="st1" points="15.44,8.71 20.82,23.41 14.38,10.2 	"/>
        <polygon className="st1" points="9.85,11.01 7.1,19.13 8.84,10.33 	"/>
        <polygon className="st1" points="12.31,10.67 15.76,19.29 13.38,10.1 	"/>
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconLogo.defaultProps = {
  className: null,
};

IconLogo.propTypes = {
  className: string,
};

export default IconLogo;
