import React from 'react';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';

import css from './FAQPage.css';
import { StaticPage, TopbarContainer } from '../../containers';

const FAQPage = () => {
  return (
    <StaticPage
      title="FAQ | Jelly Places"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'Frequently Asked Questions for Jelly Places',
        name: 'FAQ',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h1 className={css.pageTitle}>Jelly Places FAQ</h1>

          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <h2>About Hosting your Jelly Place</h2>

              <h3>How it Works</h3>
              <ol>
                <li>Create an account</li>
                <li>Setup a profile</li>
                <li>
                  Create Jelly Places listing(s) for approval. This is a requirement before going
                  live
                </li>
                <li>
                  As an approved Jelly Places host, accept or decline booking request at your
                  discretion
                </li>
                <li>
                  Fulfill booking request(s), greet the explorer(s), and let them Get Jelly in your
                  inspirational environment
                </li>
                <li>
                  Afterwards, you’ll receive your digital payment for providing an exceptional
                  experience for your Jelly Places community
                </li>
              </ol>

              <h3>What is the purpose of being a Jelly Places host?</h3>
              <p>
                You’ll help the world become a better place with purpose. There’s nothing better
                than personal human-to-human contact (meeting, working, and community). At the same
                time, you’ll make money, people will have access to you and your inspirational
                place, and the world will get a breath of fresh air with less CO2 emissions. Win,
                Win, Win. :)
              </p>

              <h3>Can I be a Jelly Places host and explorer?</h3>
              <p>
                Absolutely. Once you become an official Jelly Places host, you can easily switch
                over and start exploring for inspirational places to meet, work, and connect.
              </p>

              <h2>About exploring Jelly Places</h2>

              <h3>How it works?</h3>
              <ol>
                <li>Create an account</li>
                <li>Setup a profile</li>
                <li>Search for inspirational Jelly Places that fit your needs</li>
                <li>Submit request to book Jelly Places location(s) for approval from the host</li>
                <li>Process a digital payment</li>
                <li>
                  Meet the host, make a personal connection, and Get Jelly in your well-deserved
                  Jelly Places environment
                </li>
                <li>After your experience, leave a Get Jelly review</li>
              </ol>

              <h3>
                Why should become an explorer of Jelly Places and Get Jelly with the community?
              </h3>
              <p>
                For the first time, you will have access to meet, work, and connect in available
                inspirational places and with people that are interesting to you. You’ll also have
                access to a list of fun and impactful local experiences such as Police ride along
                activities and much more. Additionally, your participation will help the environment
                breath better when you book inspirational places that reduces your commute across
                town. In short, we thrive on putting our Get Jelly boots on the ground to get you
                access. All you have to do is Get inspired, Book it, and Get Jelly “make it happen”.
                Win, Win, Win. :)
              </p>

              <h3>How should I search to Get Jelly?</h3>
              <p>
                The categories are industry focused. First, think about the type of inspirational
                environment or person that aligns with your interests, time, and location. Second,
                think about the functional aspects of your needs i.e. Seat, Desk, or Office and etc.
                Third, book it for approval and Get Jelly.
              </p>

              <h3>Can I be a Jelly Places explore and host?</h3>
              <p>
                Absolutely. Once you create your profile as a Jelly Places explorer, you can become
                an official Jelly Places host by following the setup process noted in the “About
                hosting - How it works” FAQ section.
              </p>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default FAQPage;
