import React from 'react';
import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ExternalLink,
} from '../../components';
import css from './AboutPage.css';
import image from './rudford-hamon-jelly-places.jpg';

const AboutPage = () => {
  const { siteTwitterHandle, siteFacebookPage, siteInstagramPage } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);
  // prettier-ignore
  return (
    <StaticPage
      title="About Us | Jelly Places"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'About Jelly Places',
        name: 'About page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer/>
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h1 className={css.pageTitle}>About Jelly Places</h1>
          <img className={css.coverImage} src={image} alt="About Jelly Places, LLC."/>

          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <h2>
                Finding somewhere inspiring to work, meet and connect is impossible. Letting the
                world know that your business or space is available to inspire them in their
                pursuits simply does not exist.
              </h2>

              <p>
                Jelly Places is a digital marketplace that enables people and businesses to find,
                book and list inspirational places to work, meet and connect.
              </p>

              <p>
                Bringing the world together through places of work, community and convenience
                is our vision.
              </p>

              <p>
                As we <i>get jelly</i> together, we accomplish our mission: “Enable people to work
                from anywhere – delivering inspiration on demand.”
              </p>

              <p>
                Created in The City Beautiful, Coral Gables, Florida.
              </p>

              <p id="contact">
                Check out our{' '}
                <ExternalLink href={siteInstagramPage}>Instagram</ExternalLink>,{' '}
                <ExternalLink href={siteFacebookPage}>Facebook</ExternalLink> and{' '}
                <ExternalLink href={siteTwitterPage}>Twitter</ExternalLink>{' '}
                to stay current with all our updates, news and events.
              </p>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer/>
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};
export default AboutPage;
