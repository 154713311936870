import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './PrivacyPolicy.css';
import { NamedLink } from '../../components';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last Updated: January 14, 2020</p>

      <p className={css.legalAlign}>
        JELLY PLACES is committed to safeguarding your privacy. This Privacy Policy (this
        “<strong>Policy</strong>”)
        describes how JELLY PLACES may collect, use, share, and keep information about you and what
        choices you have. This Policy applies to JELLY PLACES' websites, progressive web apps, web
        apps, native apps, desktop applications, or online offerings (collectively, the
        “<strong>Platform</strong>”),
        and will be available by link on all sites and offerings which it covers. You should review
        the applicable privacy policy available.
      </p>

      <p className={css.legalAlign}>
        JELLY PLACES reevaluates this Policy on an ongoing basis and reserves the right to change
        its Policy at any time in its sole discretion. All changes to the terms of this Policy shall
        be effective as of the date on which they are posted on the Platform.
      </p>

      <p className={css.legalAlign}>
        Your use of the Platform or offerings covered by this Policy constitutes your consent to the
        practices described herein. Capitalized terms not defined in this Privacy Policy have the
        meanings set forth in the Terms of Use (located at{' '}
        <NamedLink name="TermsOfServicePage"
                   className={css.link}>jellyplaces.com/terms-of-use</NamedLink>).
        If you do not agree to abide by this or any future Privacy Policy, do not use, access or
        participate in (or continue to use, access or participate in) the Platform. We encourage you
        to periodically review this page for the latest information on our privacy practices.
      </p>

      <p className={css.legalAlign}>
        JELLY PLACES collects information and data about you and, if applicable, your companies in a
        continuous effort to improve your experience and to communicate with you about its products,
        services, and promotions. JELLY PLACES may collect such information as your name, e-mail and
        postal addresses, credit card numbers, company information, descriptions of properties, and
        other personally identifying information that you provide to JELLY PLACES (hereinafter,
        collectively “<strong>Information</strong>”). JELLY PLACES may, from time to time, append
        data from other
        sources to supplement the Information you provide to enhance the information JELLY PLACES
        provides to you.
      </p>

      <p className={css.legalAlign}>
        You can update or delete your personal information on the Platform or by sending an e-mail
        to <a href="mailto:help@jellyplaces.com">help@jellyplaces.com</a>. If you wish to make any
        such changes or deletions, you should be
        sure to include your Platform e-mail address, full name, and password, if applicable.
      </p>

      <p className={css.legalAlign}>
        JELLY PLACES is based in the United States, and the information we collect is governed by
        U.S. law and the laws of the other countries in which we operate. By accessing or using our
        Platform or otherwise providing information to us, you understand and consent to the
        processing and transfer of information in the U.S. and other countries for the purposes set
        out in this Privacy Policy.
      </p>

      <h2>INFORMATION COLLECTED; USE OF INFORMATION</h2>

      <p className={css.legalAlign}>
        JELLY PLACES may use the Information collected on or from the Platform to enhance and
        personalize your experience with JELLY PLACES. JELLY PLACES may also collect e-mail
        addresses at various locations within the Platform, including at such time when you register
        on the Platform, in order to send you any necessary e-mail messages related to your
        services.
      </p>

      <p className={css.legalAlign}>
        Once you register with JELLY PLACES, you may create your Profile. Your Profile and contact
        information are displayed to other Users, including Hosts at Spaces at which you make or
        request reservations and tours.
      </p>

      <p className={css.legalAlign}>
        JELLY PLACES may gather date, time, information about a user’s browser and system or device
        configuration and capabilities, information about the device you use when using our
        Platform, navigation history, and IP address for all visitors to the Platform to monitor and
        track Platform use and traffic patterns in order to provide a more personalized experience
        on the Platform. JELLY PLACES may use this information for its internal security audit log,
        for trend analysis and system administration, and to gather broad information about its
        audiences. However, JELLY PLACES does not routinely link IP addresses to anything that
        identifies you as an individual.
      </p>

      <p className={css.legalAlign}>
        JELLY PLACES may also obtain information from other sources and combine that with the
        information we collect through our Platform. For example, when you create or log into your
        account through a social media site, JELLY PLACES will have access to certain information
        from that site, such as your name, account information and list of friends and connections,
        in accordance with the authorization procedures determined by such social media sites except
        to the extent that your chosen social media platform allows you to disable this
        functionality. We may use publicly available tools, such as Google Analytics, to better
        understand users’ preferences. Collection of data by third-party tools is subject to their
        respective privacy policies.
      </p>

      <p className={css.legalAlign}>
        JELLY PLACES may allow others to provide analytics services. These entities may use cookies,
        web beacons, and other technologies to collect personal information about the use of our
        Platform, including your IP address, web browser, pages viewed, time spent on pages, links
        clicked, and conversion information. JELLY PLACES and others may use this information to,
        among other things, analyze and track data, determine the popularity of certain content, and
        better understand user online activity.
      </p>

      <p className={css.legalAlign}>
        JELLY PLACES may also use cookies and other commonly used data collection technologies on
        the Platform and in its newsletters and e-mails to collect information on both an aggregate
        and individual basis.
      </p>

      <p className={css.legalAlign}>
        In addition to the above, we may use the following technologies to automatically collect
        information about your activities on our Platform: cookies, web beacons, clear pixels, or
        pixel tags, web server logs, and geo-location technologies.
      </p>

      <p className={css.legalAlign}>
        Having accurate information about you permits us to provide you with a smooth, efficient,
        and customized experience. Specifically, we may use information collected about you via the
        Platform to:
        <ol className={css.legalOrderedlist}>
          <li>Administer sweepstakes, promotions, and contests;</li>
          <li>Assist law enforcement and respond to subpoena;</li>
          <li>Email you account-related notices, such as reservation confirmation and invoices;</li>
          <li>
            Compile anonymous statistical data and analysis for use internally or with third
            parties;
          </li>
          <li>Create and manage your account;</li>
          <li>
            Deliver targeted advertising, coupons, newsletters, and other information regarding
            promotions and the Platform to you;
          </li>
          <li>Email you regarding your account;</li>
          <li>Enable user-to-user communications;</li>
          <li>
            Fulfill and manage purchases, orders, payments, and other transactions related to the
            Platform;
          </li>
          <li>
            Generate a personal profile about you to make future visits to the Platform more
            personalized;
          </li>
          <li>Increase the efficiency and operation of the Platform;</li>
          <li>Monitor and analyze usage and trends to improve your experience with the Platform;
          </li>
          <li>
            Send you technical notices, updates, security alerts, support messages, and
            administrative messages;
          </li>
          <li>Offer new products, services, mobile applications, and/or recommendations to you;</li>
          <li>Perform other business activities as needed;</li>
          <li>
            Prevent fraudulent transactions, monitor against theft, and protect against criminal
            activity;
          </li>
          <li>Process payments and refunds;</li>
          <li>Request feedback and contact you about your use of the Platform;</li>
          <li>Resolve disputes and troubleshoot problems;</li>
          <li>Respond to your comments, questions, and requests, and provide customer service;</li>
          <li>Send you a newsletter;</li>
          <li>Solicit support for the Platform.</li>
        </ol>
      </p>

      <h2>SECURITY</h2>

      <p className={css.legalAlign}>
        JELLY PLACES uses reasonable administrative, technical, and physical security measures to
        protect your information. Because of the constitution of the Internet, however, the rules of
        data protection and the security measures mentioned above may not be observed by other
        persons or institutions beyond JELLY PLACES' control. Especially unencrypted data - even if
        sent via e-mail - can be read by third parties. JELLY PLACES has no technical influence to
        avoid this. You have the responsibility to protect the information you provide, especially
        user name and passwords, from misuse, whether by encryption or other means.
      </p>

      <h2>OTHER IMPORTANT INFORMATION</h2>

      <p className={css.legalAlign}>JELLY PLACES may collect, store, and process information on
        servers around the world, and
        may access that data globally.</p>

      <p className={css.legalAlign}>
        The Platform contains links to other websites not owned by JELLY PLACES. JELLY PLACES does
        not control the content or privacy practices of those sites.
      </p>

      <p className={css.legalAlign}>
        With your consent, JELLY PLACES may send push notifications or alerts to your mobile device
        to provide information, service updates, promotional communications, and other related
        messages. You can deactivate these notifications by changing your notification settings
        within individual mobile applications or on your device.
      </p>

      <p className={css.legalAlign}>
        JELLY PLACES recognizes that it must maintain and use customer information responsibly. In
        that regard, JELLY PLACES reserves the right to disclose information about you to: (A)
        vendors that work on JELLY PLACES’ behalf; (B) advertisers and investors for the purpose of
        conducting general business analysis or for marketing purposes, as permitted by law; and (C)
        to the following third parties, if JELLY PLACES has a good-faith belief that such disclosure
        is reasonably necessary: (i) to be made to law enforcement to comply with a court order,
        ongoing judicial proceeding, or other legal process served on JELLY PLACES; (ii) to exercise
        JELLY PLACES’ legal rights or to defend against legal claims; (iii) to protect human safety
        or JELLY PLACES’ networks or property; or (iv) as part of a merger or sale of JELLY PLACES’
        business assets.
      </p>

      <p className={css.legalAlign}>
        We may use or disclose personally-identifying information if we have a good-faith belief
        that the disclosure is reasonably necessary (a) to satisfy applicable law, regulation, legal
        process, or enforceable governmental request, (b) to enforce agreements we have with you,
        (c) to address fraud or security issues, or (d) to protect against harm to the rights,
        property, or safety of the Publisher, or our employees or customers.
      </p>

      <p className={css.legalAlign}>
        JELLY PLACES’ Platform and offerings are directed to entities and/or individuals in their
        business and professional capacities. They are not intended for children under thirteen (13)
        years of age. JELLY PLACES does not knowingly solicit data online from, or market online to,
        children under the age of thirteen (13) years. In addition, children under the age of
        thirteen (13) years are not permitted to submit any personal information to JELLY PLACES’
        Platform.
      </p>

      <h2>CONTACT</h2>

      <p className={css.legalAlign}>
        For questions regarding this Policy, practices of the Platform, or any other transaction
        issue, please send an e-mail to <a
        href="mailto:help@jellyplaces.com">help@jellyplaces.com</a>.
      </p>

      <p className={css.legalAlign}>
        If you feel that JELLY PLACES is not following this stated Privacy Policy, please contact us
        through the above e-mail link.
      </p>

    </div>
  );
};
PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};
const { string } = PropTypes;
PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};
export default PrivacyPolicy;
