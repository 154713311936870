import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink } from '../../components';

import css from './SectionLocations.css';

import artFashionDesign from './images/cat-art-fashion-design.jpg';
import hospitalityServices from './images/cat-hospitality-services.jpg';
import foodBev from './images/cat-food-beverage.jpg';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = (name, image, searchQuery) => {
  const nameText = <span className={css.locationName}>{name}</span>;
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.linkText}>
        <FormattedMessage
          id="SectionLocations.listingsInLocation"
          values={{ location: nameText }}
        />
      </div>
    </NamedLink>
  );
};

const SectionLocations = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionLocations.title" />
      </div>
      <div className={css.locations}>
        {locationLink(
          'Art, Fashion & Design',
          artFashionDesign,
          '?address=Miami&bounds=25.9396026%2C-80.10512187%2C25.71730808%2C-80.28201992&pub_category=art_fashion_design'
        )}
        {locationLink(
          'Hospitality & Services',
          hospitalityServices,
          '?address=Miami&bounds=25.9396026%2C-80.10512187%2C25.71730808%2C-80.28201992&pub_category=hospitality_services'
        )}
        {locationLink(
          'Food & Beverage',
          foodBev,
          '?address=Miami&bounds=25.8775662%2C-80.10575028%2C25.6551552%2C-80.28264833&mapSearch=true&pub_category=food_beverage'
        )}
      </div>
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
