import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
export default [
  {
    id: 'default-miami',
    predictionPlace: {
      address: 'Miami, FL',
      bounds: new LatLngBounds(
        new LatLng(25.9396026, -80.10512187),
        new LatLng(25.71730808, -80.28201992)
      ),
    },
  },
  {
    id: 'default-coral-gables',
    predictionPlace: {
      address: 'Coral Gables, FL',
      bounds: new LatLngBounds(
        new LatLng(25.79100652, -80.2097298),
        new LatLng(25.64808028, -80.32336357)
      ),
    },
  },
  {
    id: 'default-houston',
    predictionPlace: {
      address: 'Houston, TX',
      bounds: new LatLngBounds(
        new LatLng(29.9585992, -95.19674529),
        new LatLng(29.55910574, -95.52636726)
      ),
    },
  },
  {
    id: 'default-the-woodlands',
    predictionPlace: {
      address: 'The Woodlands, TX',
      bounds: new LatLngBounds(
        new LatLng(30.21705274, -95.44698632),
        new LatLng(30.11758869, -95.52939181)
      ),
    },
  },
];
