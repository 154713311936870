import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput, NamedLink } from '../../components';
import * as validators from '../../util/validators';

import css from './NotifyCommunityForm.css';

const NotifyCommunityFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        intl,
        invalid,
      } = fieldRenderProps;

      // notification text
      const notificationLabel = intl.formatMessage({
        id: 'NotifyCommunityForm.notificationLabel',
      });
      const notificationPlaceholder = intl.formatMessage({
        id: 'NotifyCommunityForm.notificationPlaceholder',
      });
      const notificationRequiredMessage = intl.formatMessage({
        id: 'NotifyCommunityForm.notificationRequired',
      });
      const notificationRequired = validators.required(notificationRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <FieldTextInput
              type="textarea"
              id={formId ? `${formId}.notificationText` : 'notificationText'}
              name="notificationText"
              label={notificationLabel}
              placeholder={notificationPlaceholder}
              validate={notificationRequired}
            />
          </div>
          <div className={css.bottomWrapper}>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="NotifyCommunityForm.submit" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

NotifyCommunityFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
};

const { string, bool } = PropTypes;

NotifyCommunityFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
};

const NotifyCommunityForm = compose(injectIntl)(NotifyCommunityFormComponent);
NotifyCommunityForm.displayName = 'NotifyCommunityForm';

export default NotifyCommunityForm;
