import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { NotifyCommunityForm } from '../../forms';
import { TopbarContainer } from '../../containers';
import { notifyCommunity } from './NotifyCommunityPage.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';

import css from './NotifyCommunityPage.css';

export class NotifyCommunityPageComponent extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      currentUser,
      intl,
      onNotifyCommunity,
      notifyCommunityInProgress,
      notifyCommunitySuccess,
      notifyCommunityError,
      scrollingDisabled,
    } = this.props;

    const user = ensureCurrentUser(currentUser);
    const currentUserLoaded = !!user.id;

    if( !currentUserLoaded ){
      return null;
    }

    const notifyErrorMessage = notifyCommunityError ? (
      <div className={css.error}>
        <FormattedMessage id="NotifyCommunityPage.notificationFailed" />
      </div>
    ) : null;

    const notifySuccessMessage = notifyCommunitySuccess ? (
      <div className={css.success}>
        <FormattedMessage id="NotifyCommunityPage.notificationSucceeded" />
      </div>
    ) : null;

    const handleSubmitNotification = values => {
      const { notificationText } = values;

      onNotifyCommunity(notificationText);
    };

    const formContent = (
      <div className={css.content}>
        {notifyErrorMessage}
        {notifySuccessMessage}
        <NotifyCommunityForm className={css.form} onSubmit={handleSubmitNotification} inProgress={notifyCommunityInProgress} />
      </div>
    );

    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'NotifyCommunityPage.schemaTitleLogin' }, { siteTitle });

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          name: schemaTitle,
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer/>
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.layoutWrapperMain}>
            <div className={css.root}>
              {formContent}
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

NotifyCommunityPageComponent.defaultProps = {
  currentUser: null,
};

const { bool, object, shape } = PropTypes;

NotifyCommunityPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  isAuthenticated: bool.isRequired,
  scrollingDisabled: bool.isRequired,

  // from withRouter
  location: shape({ state: object }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;
  const {
    notifyCommunityInProgress,
    notifyCommunitySuccess,
    notifyCommunityError
  } = state.NotifyCommunityPage;

  return {
    currentUser,
    isAuthenticated,
    scrollingDisabled: isScrollingDisabled(state),
    notifyCommunityInProgress,
    notifyCommunitySuccess,
    notifyCommunityError,
  };
};

const mapDispatchToProps = dispatch => ({
  onNotifyCommunity: notificationText =>
    dispatch(notifyCommunity(notificationText)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const NotifyCommunityPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(NotifyCommunityPageComponent);

export default NotifyCommunityPage;
