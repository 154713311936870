import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { bool, func } from 'prop-types';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
} from '../../components';

const onStripeOnboardingComplete = (history, userId, stripeAccountId) => {
  return axios({
    url: `${process.env.REACT_APP_HMS_ROOT_URL}/stripe-onboarding/finalize`,
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    data: JSON.stringify({ userId, stripeAccountId }),
  })
    .then(() => {
      history.push(createResourceLocatorString('PayoutPreferencesPage', routeConfiguration(), {}, {}));
    })
    .catch(error => {
      console.error('Error initializing Stripe Onboarding : ' + error.message);
      history.push(createResourceLocatorString('PayoutPreferencesPage', routeConfiguration(), {}, {}));
    });

};

export const OnboardingCompletePageComponent = props => {
  const [onboardingFinishing, setOnboardingFinishing] = useState(false);

  const {
    currentUser,
    scrollingDisabled,
    history,
    intl,
  } = props;

  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!ensuredCurrentUser.id;
  const userId = currentUserLoaded && !!ensuredCurrentUser.id && ensuredCurrentUser.id.uuid;
  const stripeAccountId =
    currentUserLoaded &&
    !!ensuredCurrentUser.stripeAccount &&
    ensuredCurrentUser.stripeAccount.attributes.stripeAccountId;

  if(userId && stripeAccountId && !onboardingFinishing){
    setOnboardingFinishing(true);

    onStripeOnboardingComplete(history, userId, stripeAccountId);
  }

  const title = intl.formatMessage({ id: 'OnboardingCompletePage.title' });

  let message = <FormattedMessage id="OnboardingCompletePage.loadingData" />;

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <p>{message}</p>
    </Page>
  );
};

OnboardingCompletePageComponent.defaultProps = {
  currentUser: null,
  createStripeAccountError: null,
};

OnboardingCompletePageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = () => ({
});

const OnboardingCompletePage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  injectIntl
)(OnboardingCompletePageComponent);

export default OnboardingCompletePage;
