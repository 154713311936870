import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, FieldTextInput, PrimaryButton } from '../../components';
import css from './ContactUsForm.css';

class ContactUsFormComponent extends Component {
  constructor(props) {
    super(props);
    this.uploadDelayTimeoutId = null;
    this.state = { uploadDelay: false, showVerificationEmailSentMessage: false };
    this.submittedValues = {};
  }

  componentWillUnmount() {
    window.clearTimeout(this.uploadDelayTimeoutId);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            saveEmailError,
            savePhoneNumberError,
            currentUser,
            form,
            formId,
            handleSubmit,
            reset,
            inProgress,
            intl,
            invalid,
            sendVerificationEmailError,
            sendVerificationEmailInProgress,
            values,
          } = fieldRenderProps;
          const { name, email, phone, subject, message } = values;
          const namePlaceholder = '';
          const emailPlaceholder = '';
          const phonePlaceholder = '';
          const subjectPlaceholder = '';
          const messagePlaceholder = '';
          const nameLabel = intl.formatMessage({
            id: 'ContactUsForm.nameLabel',
          });
          const emailLabel = intl.formatMessage({
            id: 'ContactUsForm.emailLabel',
          });
          const phoneLabel = intl.formatMessage({
            id: 'ContactUsForm.phoneLabel',
          });
          const subjectLabel = intl.formatMessage({
            id: 'ContactUsForm.subjectLabel',
          });
          const messageLabel = intl.formatMessage({
            id: 'ContactUsForm.messageLabel',
          });
          const nameRequiredMessage = intl.formatMessage({
            id: 'ContactUsForm.nameRequired',
          });
          const emailRequiredMessage = intl.formatMessage({
            id: 'ContactUsForm.emailRequired',
          });
          const subjectRequiredMessage = intl.formatMessage({
            id: 'ContactUsForm.subjectRequired',
          });
          const messageRequiredMessage = intl.formatMessage({
            id: 'ContactUsForm.messageRequired',
          });
          const nameRequired = validators.required(nameRequiredMessage);
          const emailRequired = validators.required(emailRequiredMessage);
          const subjectRequired = validators.required(subjectRequiredMessage);
          const messageRequired = validators.required(messageRequiredMessage);
          const nameInvalidMessage = intl.formatMessage({
            id: 'ContactUsForm.nameInvalid',
          });
          const emailInvalidMessage = intl.formatMessage({
            id: 'ContactUsForm.emailInvalid',
          });
          const phoneInvalidMessage = intl.formatMessage({
            id: 'ContactUsForm.phoneInvalid',
          });
          const subjectInvalidMessage = intl.formatMessage({
            id: 'ContactUsForm.subjectInvalid',
          });
          const messageInvalidMessage = intl.formatMessage({
            id: 'ContactUsForm.messageInvalid',
          });
          // TODO create validators for each form field
          const nameValid = validators.requiredStringNoTrim(nameInvalidMessage);
          const emailValid = validators.emailFormatValid(emailInvalidMessage);
          const subjectValid = validators.requiredStringNoTrim(subjectInvalidMessage);
          const messageValid = validators.requiredStringNoTrim(messageInvalidMessage);
          const nameTouched = this.submittedValues.name !== values.name;
          const emailTouched = this.submittedValues.email !== values.email;
          const phoneTouched = this.submittedValues.phone !== values.phone;
          const subjectTouched = this.submittedValues.subject !== values.subject;
          const messageTouched = this.submittedValues.message !== values.message;
          const classes = classNames(rootClassName || css.root, className);
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled = invalid || pristineSinceLastSubmit || inProgress;

          let genericError = null;

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
                setTimeout(form.reset);
              }}
            >
              <div className={css.contactDetailsSection}>
                <div>
                  <FieldTextInput
                    type="text"
                    name="name"
                    id={formId ? `${formId}.name` : 'name'}
                    label={nameLabel}
                    placeholder={namePlaceholder}
                    validate={validators.composeValidators(nameRequired, nameValid)}
                    customErrorText={nameTouched ? null : nameInvalidMessage}
                  ></FieldTextInput>
                  <div className={css.flexContainer}>
                    <FieldTextInput
                      className={css.email}
                      type="email"
                      name="email"
                      id={formId ? `${formId}.email` : 'email'}
                      label={emailLabel}
                      placeholder={emailPlaceholder}
                      validate={validators.composeValidators(emailRequired, emailValid)}
                      customErrorText={emailTouched ? null : emailInvalidMessage}
                    ></FieldTextInput>
                    <FieldTextInput
                      className={css.phone}
                      type="phone"
                      name="phone"
                      id={formId ? `${formId}.phone` : 'phone'}
                      label={phoneLabel}
                      placeholder={phonePlaceholder}
                      customErrorText={phoneTouched ? null : phoneInvalidMessage}
                    ></FieldTextInput>
                  </div>
                  <FieldTextInput
                    type="text"
                    name="subject"
                    id={formId ? `${formId}.subject` : 'subject'}
                    label={subjectLabel}
                    placeholder={subjectPlaceholder}
                    validate={validators.composeValidators(subjectRequired, subjectValid)}
                    customErrorText={subjectTouched ? null : subjectInvalidMessage}
                  ></FieldTextInput>
                  <FieldTextInput
                    type="textarea"
                    rows="3"
                    name="message"
                    id={formId ? `${formId}.message` : 'message'}
                    label={messageLabel}
                    placeholder={messagePlaceholder}
                    validate={validators.composeValidators(messageRequired, messageValid)}
                    customErrorText={messageTouched ? null : messageInvalidMessage}
                  ></FieldTextInput>
                </div>
              </div>
              <div className={css.bottomWrapper}>
                {genericError}
                <PrimaryButton
                  type="submit"
                  inProgress={inProgress}
                  ready={pristineSinceLastSubmit}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="ContactUsForm.submitContact" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

ContactUsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  inProgress: false,
  name: null,
  email: null,
  phone: null,
  subject: null,
  message: null,
};

const { bool, func, string } = PropTypes;

ContactUsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  inProgress: bool,
  intl: intlShape.isRequired,
  ready: bool,
};

const ContactUsForm = compose(injectIntl)(ContactUsFormComponent);

ContactUsForm.displayName = 'ContactUsForm';

export default ContactUsForm;
