import { storableError } from '../../util/errors';
import axios from 'axios';

// ================ Action types ================ //

export const NOTIFY_COMMUNITY_REQUEST = 'app/NotifyCommunityPage/NOTIFY_COMMUNITY_REQUEST';
export const NOTIFY_COMMUNITY_SUCCESS = 'app/NotifyCommunityPage/NOTIFY_COMMUNITY_SUCCESS';
export const NOTIFY_COMMUNITY_ERROR = 'app/NotifyCommunityPage/NOTIFY_COMMUNITY_ERROR';

// ================ Reducer ================ //

const initialState = {
  notifyCommunityInProgress: false,
  notifyCommunitySuccess: false,
  notifyCommunityError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case NOTIFY_COMMUNITY_REQUEST:
      return {
        ...state,
        stripeSubscriptionInProgress: true,
        notifyCommunitySuccess: false,
        stripeSubscriptionError: null,
      };
    case NOTIFY_COMMUNITY_SUCCESS:
      return {
        ...state,
        stripeSubscriptionInProgress: false,
        notifyCommunitySuccess: true,
      };
    case NOTIFY_COMMUNITY_ERROR:
      return {
        ...state,
        stripeSubscriptionInProgress: false,
        stripeSubscriptionError: payload
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const notifyCommunityRequest = () => ({ type: NOTIFY_COMMUNITY_REQUEST });
export const notifyCommunitySuccess = () => ({ type: NOTIFY_COMMUNITY_SUCCESS });
export const notifyCommunityError = error => ({
  type: NOTIFY_COMMUNITY_ERROR,
  payload: error,
});

// ================ Thunks ================ //

export const notifyCommunity = notificationText => (dispatch, getState, sdk) => {
  dispatch(notifyCommunityRequest());

  return axios({
    url: `${process.env.REACT_APP_HMS_ROOT_URL}/community/notify`,
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    data: JSON.stringify({ notificationText }),
  })
    .then(() => {
      return dispatch(notifyCommunitySuccess());
    })
    .catch(error => {
      console.error('Error sending notification message to community : ' + error.message);
      dispatch(notifyCommunityError(storableError(error)));
    });
};
